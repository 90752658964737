import React from "react";
import "./Footer.scss";

function Footer() {
  const openPrivacy = () => {
    window.open("/Privacy Policy.pdf", "_blank");
  };

  const openTerms = () => {
    window.open("/Terms of Service.pdf", "_blank");
  };

  return (
    <div className="footer">
      <span className="center-text">©2024, tomi. All rights reserved.</span>
      <div className="content">
        <p onClick={openPrivacy}>Privacy Policy</p>
        <p onClick={openTerms}>Terms of Service</p>
      </div>
    </div>
  );
}

export default Footer;
