import { createSlice } from "@reduxjs/toolkit";
import { BTN_LABLE } from "../../constants";

const initialState = {
  connectBtnLable: BTN_LABLE.CONNECT,
  mainLoading: false,
  wltSignature: null,
  userTempData: {
    name: "",
    email: "",
    useCase: "",
  },
  containerFromDashBoard: false,
};

export const uiSettings = createSlice({
  name: "uiSettings",
  initialState,
  reducers: {
    setConnectButton: (state, action) => {
      state.connectBtnLable = action.payload;
    },
    setMainLoading: (state, action) => {
      state.mainLoading = action.payload;
    },
    setUserTempData: (state, action) => {
      state.userTempData = {
        name: action.payload?.name ?? "",
        email: action.payload?.email ?? "",
        useCase: action.payload?.useCase ?? "",
      };
    },
    setWltSignature: (state, action) => {
      state.wltSignature = action.payload;
    },
    setContainerFromDashBoard: (state, action) => {
      state.containerFromDashBoard = action.payload;
    },
  },
});

export const {
  setConnectButton,
  setMainLoading,
  setUserTempData,
  setWltSignature,
  clearUploadFileQueue,
  appendToUploadFileQueue,
  removeFromUploadFileQueue,
  setContainerFromDashBoard,
} = uiSettings.actions;

export default uiSettings.reducer;
