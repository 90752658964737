import {
  DeletedFile,
  DownloadFile,
  FileMangeIcon,
  PinkArrow,
} from "../../../Assets/Assets";
import "./UserLogCard.scss";
import { useSelector } from "react-redux";
import { Col, Skeleton, Tooltip } from "antd";
import httpRequest from "../../../Utils/httpsRequest";
import useLogout from "../../../CustomHooks/useLogout";
import MediaDisplay from "../MediaDisplay/MediaDisplay";
import React, { useEffect, useState, useRef } from "react";
import { convertDate, formatStorageSize } from "../../../Utils/helpers";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../../constants";

function UserLogCard() {
  const handleLogOut = useLogout();
  const [userLogs, setUserLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });
  const scrollContainerRef = useRef(null);
  const { csrfToken } = useSelector((state) => state.auth);

  const isMobile = () => window.innerWidth < 768;

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      const handleScroll = () => checkArrowVisibility();
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const fetchMatriceData = async () => {
      try {
        const response = await httpRequest(
          API_URL.USER_LOGS,
          HTTP_METHOD.GET,
          null,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );
        if (response?.code === HTTP_STATUS_CODE.OK) {
          setUserLogs(response?.data);
        } else {
          console.log(response?.response?.data?.message);
        }
      } catch (error) {
        console.error("Error fetching matrix data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatriceData();
  }, []);

  const handleClick = (record) => {
    if (record.type !== "deleted") {
      setIsModalOpen(true);
      setSelectedFile(record);
    }
  };

  const getIconByType = (type) => {
    switch (type) {
      case "upload":
        return (
          <span>
            <FileMangeIcon /> Upload
          </span>
        );
      case "download":
        return (
          <span>
            <DownloadFile /> Download
          </span>
        );
      case "deleted":
        return (
          <span>
            <DeletedFile /> Delete
          </span>
        );
      default:
        return null;
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: -250,
        behavior: "smooth",
      });
      checkArrowVisibility();
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        top: 0,
        left: 250,
        behavior: "smooth",
      });
      checkArrowVisibility();
    }
  };

  const checkArrowVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollLeft = container.scrollLeft;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;

      setShowArrows({
        left: scrollLeft > 0,
        right: scrollLeft + clientWidth < scrollWidth,
      });
    }
  };

  useEffect(() => {
    if (userLogs.length) {
      checkArrowVisibility();
    }
  }, [userLogs]);

  return (
    <div className="userLogCard">
      {loading ? (
        <div className="cardskeleton">
          {[1, 2, 3, 4, 5, 6].map((_, index) => (
            <Col
              key={index + "skele"}
              lg={6}
              className="setting_card commonCardBg"
            >
              <Skeleton active paragraph={{ rows: 2 }} />
              <Skeleton.Button active />
            </Col>
          ))}
        </div>
      ) : userLogs.length ? (
        <>
          <h3>Activity Logs</h3>
          <div className="slider-wrapper">
            {!isMobile() && showArrows.left && (
              <button
                className="arrow-button back-arrow-button"
                onClick={scrollLeft}
              >
                <PinkArrow />
              </button>
            )}
            <div className="slider-container" ref={scrollContainerRef}>
              {userLogs.map((item, index) => (
                <div
                  onClick={() => handleClick(item)}
                  key={index}
                  className={`slider-item commonCardBg ${item.type === "deleted" ? "disable-cursor" : ""}`}
                >
                  <div>
                    <p className="uploadText">
                      <span>{getIconByType(item.type)}</span>
                    </p>
                    {item.name.length > 20 ? (
                      <Tooltip placement="top" title={item.name}>
                        <h2 className="cursor-pointer ">{item.name}</h2>
                      </Tooltip>
                    ) : (
                      <h2 className="cursor-pointer ">{item.name}</h2>
                    )}
                  </div>
                  <div className="slider-item_progress progressCommon">
                    <p>{formatStorageSize(item?.size)}</p>
                    <p className="slider-item_timeAgo">
                      {convertDate(item.created_at)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {!isMobile() && showArrows.right && (
              <button className="arrow-button" onClick={scrollRight}>
                <PinkArrow />
              </button>
            )}
          </div>
        </>
      ) : (
        <h3>No logs available</h3>
      )}
      {ismodalOpen && (
        <MediaDisplay
          selectedMedia={selectedFile}
          setModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
}

export default UserLogCard;
