import React from "react";
import { Modal } from "antd";
import "./CommonModal.scss";
import PropTypes from "prop-types";
import useKeyPress from "../../Utils/validationUtils";

const CommonViewModal = ({
  visible,
  title,
  icon,
  content,
  onOk,
  onCancel,
  onClick,
}) => {
  useKeyPress("Enter", onClick, [onClick]);
  useKeyPress("Escape", onCancel, [onCancel]);
  return (
    <Modal
      open={visible}
      onOk={onOk}
      centered
      onCancel={onCancel}
      footer={null}
      className="common-modal commonViewModal"
    >
      <div className="modal-header">
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
      </div>
      <div className="modal-content">{content}</div>
    </Modal>
  );
};

CommonViewModal.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  content: PropTypes.node.isRequired,
  BtnText: PropTypes.string.isRequired,
  onOk: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  paragraph: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
};

export default CommonViewModal;
