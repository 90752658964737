import "./AccountType.scss";
// import { Switch } from "antd";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useLogout from "../../CustomHooks/useLogout";
import httpRequest from "../../Utils/httpsRequest";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber, formatStorageSize } from "../../Utils/helpers";
// import faitIcon from "../../Assets/images/FAITIcon.svg";
import TomiToken from "../../Assets/images/TomiToken.svg";
import { fetchPlans } from "../../store/slices/planSlice";
import ERC20Contract from "../../helper/erc20.connection";
import { Path } from "../../Routing/Constant/RoutePaths";
import { setPlanType } from "../../store/slices/userSlice";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { ProAccountIcon, ScaleIcon } from "../../Assets/Assets";
import { setMainLoading } from "../../store/slices/uiSettingSlice";
import AccountCard from "../../Common/Cards/AccountCard/AccountCard";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";
import HeaderCommon from "../../Common/Components/HeaderCommon/HeaderCommon";

function AccountTypeDashboard() {
  const perMonth = " per month";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const [capValue, setCapValue] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  const [userBalance, setUserBalance] = useState(0);
  const { plans, loading } = useSelector((state) => state.plan);
  const [
    showTokenValue,
    // setShowTokenValue
  ] = useState(true);
  const { csrfToken, walletAddress } = useSelector((state) => state.auth);

  useEffect(() => {
    try {
      (async () => await fetchBalance())();

      dispatch(fetchPlans({ csrfToken, handleLogOut }));

      (async () => {
        const response = await httpRequest(
          `${API_URL.COIN_MARKET_CAP}`,
          HTTP_METHOD.GET,
          null,
          null,
          null,
          handleLogOut
        );

        if (response.code === HTTP_STATUS_CODE.OK) {
          console.log("response?.data", response.data?.price);
          setCapValue(Number(response.data?.price ?? 0));
        } else {
          toast.error("Something went wrong!");
        }
      })();
    } catch (error) {
      console.log("Error while fetching cap api : ", error);
    }
  }, []);
  const generateFeatures = (plan) => {
    return [
      `${formatStorageSize(plan?.storage)} Storage included`,
      `${formatStorageSize(plan?.meta_data?.bandwidth)} Bandwidth`,
      `${plan?.meta_data?.workspace} ${plan?.meta_data?.workspace == 1 ? "Workspace Member" : "Workspace Members"}`,
      `${plan?.meta_data?.gateway}  ${plan?.meta_data?.gateway === 1 ? "Gateway" : "Gateways"}`,
      `${formatNumber(plan?.meta_data?.requests)}  requests${perMonth}`,
      `Maximum number of files pinned can be ${formatNumber(plan?.meta_data?.pinned)}`,
      `${plan?.meta_data?.html_file === true ? "HTML Upload Supported" : "No HTML Upload Supported"}`,
    ];
  };

  // const onChange = (checked) => {
  //   setShowTokenValue(checked);
  // };

  const fetchBalance = async () => {
    try {
      dispatch(setMainLoading(true));
      const erc20 = await ERC20Contract.init(walletProvider);
      const balance = await erc20.getBalance(walletAddress);
      const decimals = await erc20.getDecimals(walletAddress);

      if (balance > 0) {
        setUserBalance(Number(balance) / 10 ** (Number(decimals) ?? 18));
      }
      dispatch(setMainLoading(false));
      return true;
    } catch (error) {
      console.error("Error fetching balance:", error);
      dispatch(setMainLoading(false));
      return false;
    }
  };

  const handleSubmit = async (e) => {
    switch (e) {
      case "free": {
        const result = plans.find((item) => item["name"] === "free");

        console.log("result : ", result);

        if (userBalance >= result?.holdings) {
          const response = await httpRequest(
            API_URL.TRIAL_PLAN,
            HTTP_METHOD.POST,
            null,
            {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            null,
            handleLogOut
          );
          console.log("response : ", response);

          if (response.code === HTTP_STATUS_CODE.OK) {
            dispatch(setPlanType("free"));
            navigate(Path.DASHBOARD);
          } else {
            toast.error("Something went wrong!");
          }
        } else {
          toast.error(
            `A minimum of ${result?.holdings ?? 1000} Tomi tokens is required to access the free plan`
          );
        }
        break;
      }

      case "standard":
        break;

      case "pro":
        break;

      default:
        break;
    }
  };

  return (
    <div className="accountType dashAcType">
      <HeaderCommon DisconnectBtn />
      <h2>Choose Your Plan</h2>
      {/* <div className="accountType_switch">
        <div className="accountType_switch_head">
          <img src={faitIcon} alt="" />
          <h5>Pay with FIAT</h5>
        </div>
        <Switch defaultChecked onChange={onChange} />
        <div className="accountType_switch_head">
          <img src={TomiToken} alt="" />
          <h5>Pay with tomi token</h5>
        </div>
      </div> */}

      <div className="accountType_cards ">
        {plans &&
          plans?.map((plan, index) => (
            <AccountCard
              key={index}
              title={plan?.name}
              // price={`$${plan?.price || 0}`}
              // price={`$${Math.round((plan?.price ?? 0) / capValue * 100) / 100}`}
              price={`$${plan?.price ?? 0}`}
              duration="mo"
              TokenValue={showTokenValue ? showTokenValue : null}
              valueIconSrc={TomiToken}
              description={plan?.description || ""}
              features={generateFeatures(plan)}
              buttonText={
                plan?.name === "free"
                  ? "Start Free Trail"
                  : plan?.name === "pro"
                    ? "Start Pro Account"
                    : plan?.name === "scale"
                      ? "Start Scale Account"
                      : ""
              }
              onClick={handleSubmit}
              // value={plan?.price}
              value={`${Math.round(((plan?.price ?? 0) / capValue) * 100) / 100}`}
              icon={
                plan?.name == "pro" ? (
                  <ProAccountIcon />
                ) : plan?.name == "scale" ? (
                  <ScaleIcon />
                ) : null
              }
              btnDisabled={index !== 0}
              btnActive={true}
              loading={loading}
            />
          ))}
      </div>
    </div>
  );
}

export default AccountTypeDashboard;
