import React, { useState, useEffect } from "react";
import CommonModal from "../../Common/CommonModal/CommonModal";
import "./ReportMediaModal.scss";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { validateMail } from "../../Utils/validationUtils";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Turnstile } from "@marsidev/react-turnstile";
import { env } from "../../constants/env";
import { API_URL, ERR_MSG, HTTP_METHOD, REGEX } from "../../constants";
import useLogout from "../../CustomHooks/useLogout";
import { useSelector } from "react-redux";
import httpRequest from "../../Utils/httpsRequest";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";

function ReportMediaModal({
  show,
  handleClick,
  closeModal,
  mediaId,
  setShow,
  contactDetail,
  setContactDetail,
}) {
  const ref = React.useRef();

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    category: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState("");
  const handleLogout = useLogout();
  const { csrfToken } = useSelector((state) => state.auth);
  const getLink = window.location.href;

  useEffect(() => {
    const allFieldsFilled =
      contactDetail.firstName &&
      contactDetail.lastName &&
      contactDetail.emailId &&
      contactDetail.message &&
      selectCategory;

    const noErrors =
      !errors.firstName &&
      !errors.lastName &&
      !errors.emailId &&
      !errors.category;
    setIsFormValid(allFieldsFilled && noErrors);
  }, [contactDetail, errors, selectCategory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reportData = {
      first_name: contactDetail?.firstName,
      last_name: contactDetail?.lastName,
      email: contactDetail?.emailId,
      media_id: mediaId ? mediaId : "",
      category: selectCategory,
      message: contactDetail?.message,
      captcha_token: captchaToken ? captchaToken : "",
      media_url: getLink,
    };

    setLoading(true);
    const response = await httpRequest(
      API_URL.REPORT_MEDIA,
      HTTP_METHOD.POST,
      reportData,
      {
        "X-CSRF-Token": csrfToken,
      },
      null,
      handleLogout
    );
    setLoading(false);

    if (!response?.error) {
      toast.success("Media Reported submitted successfully");
    }

    if (response?.error) {
      toast.error(response?.error?.message);
    }
    setContactDetail({
      firstName: "",
      lastName: "",
      emailId: "",
      message: "",
    });
    setErrors({
      firstName: "",
      lastName: "",
      emailId: "",
    });
    setSelectCategory("");
    ref.current.reset();
    setShow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactDetail((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "firstName" || name === "lastName") {
      validateName(name, value);
    } else if (name === "emailId") {
      validateEmail(value);
    }
  };

  const validateName = (fieldName, value) => {
    const name = value.trim();
    let error = "";

    if (name.length === 0) {
      error = ERR_MSG.REQUIRED_INPUT;
    } else if (name.length < 3) {
      error = ERR_MSG.SHORT_LENGTH;
    } else if (name.length >= 50) {
      error = ERR_MSG.LONG_LENGTH;
    } else if (!REGEX.STARTS_WITH_LETTER.test(name)) {
      error = ERR_MSG.STARTS_WITH_LETTER;
    } else if (/ {2,}/.test(name)) {
      error = ERR_MSG.CONSECUTIVE_SPACE;
    } else if (!REGEX.NAME.test(name)) {
      error = ERR_MSG.INVALID_NAME;
    }

    setErrors((prev) => ({
      ...prev,
      [fieldName]: error,
    }));
  };

  const validateEmail = (value) => {
    const { isValidMail, error } = validateMail(value);

    setErrors((prev) => ({
      ...prev,
      emailId: isValidMail ? "" : error,
    }));
  };

  return (
    <>
      <CommonModal
        className="reportModal"
        visible={show}
        onClick={handleClick}
        content={
          <>
            <form className="reportModal_inner" onSubmit={handleSubmit}>
              <h4>Report Media</h4>
              <p>We are here for you! How can we help?</p>

              <div className="reportModal_inner_inputs">
                <InputCustom
                  regularInput
                  placeholder="Enter First Name"
                  label
                  labletext="First Name"
                  name="firstName"
                  required
                  minLength={3}
                  maxLength={50}
                  onChange={handleChange}
                  value={contactDetail.firstName}
                  error={errors.firstName}
                />
                <InputCustom
                  regularInput
                  placeholder="Enter Last Name"
                  label
                  labletext="Last Name"
                  name="lastName"
                  required
                  onChange={handleChange}
                  value={contactDetail.lastName}
                  error={errors.lastName}
                />
              </div>

              <InputCustom
                regularInput
                placeholder="Enter Your Email ID"
                label
                labletext="Email"
                name="emailId"
                required
                onChange={handleChange}
                value={contactDetail.emailId}
                error={errors.emailId}
                customClass="margin-field"
              />
              <InputCustom
                regularInput
                placeholder="Link"
                label
                labletext="Link"
                name="link"
                required
                customClass="margin-field"
                value={getLink ? getLink : mediaId}
                disabled={true}
              />
              <CustomSelect
                regularInput
                label
                labelText="Select Category"
                required
                defaultValue={selectCategory}
                value={selectCategory}
                onChange={(value) => {
                  setSelectCategory(value);
                }}
                options={[
                  { value: "", label: "Select Category" }, // Placeholder option
                  { value: "Spam or Fraud", label: "Spam or Fraud" },
                  {
                    value: "Disturbing or Inappropriate Content",
                    label: "Disturbing or Inappropriate Content",
                  },
                  { value: "Other Activity", label: "Other Activity" },
                ]}
                customClass="margin-field"
                error={errors.category}
              />
              <InputCustom
                textareaInput
                placeholder="Type your message...."
                label
                labletext="Message"
                name="message"
                required
                onChange={handleChange}
                value={contactDetail.message}
                customClass="margin-field"
              />

              <Turnstile
                ref={ref}
                siteKey={env.captchaKey}
                onSuccess={(token) => {
                  console.log(token);
                  setCaptchaToken(token);
                }}
                onError={(error) => {
                  console.log("Error in captcha : ", error);
                  setCaptchaToken("");
                }}
              />

              <ButtonCustom
                className={`contact_inner_button ${!isFormValid || loading ? "disableBtn" : ""}`}
                label={
                  loading ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    "Submit"
                  )
                }
                regularBtn
                // type="submit"
                disabled={!isFormValid || loading}
              />
            </form>
          </>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />
    </>
  );
}

export default ReportMediaModal;
ReportMediaModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  handleClick: PropTypes.func,
  mediaId: PropTypes.any,
  setShow: PropTypes.func,
  contactDetail: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    emailId: PropTypes.string,
    message: PropTypes.string,
  }),
  setContactDetail: PropTypes.func,
};
