import {
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import React from "react";
import "./MediaDisplay.scss";
import PropTypes from "prop-types";
import { Image, Space } from "antd";
import { env } from "../../../constants/env";
import audio from "../../../Assets/images/audio.png";
import CommonViewModal from "../../CommonModal/CommonViewModal";
import image from "../../../Assets/images/files.png";

const MediaDisplay = ({
  selectedMedia,
  setModalOpen,
  isPublicDisplay = false,
}) => {
  const closeModal = () => {
    setModalOpen(false);
  };

  if (selectedMedia?.format?.includes("image")) {
    return (
      <>
        {isPublicDisplay ? (
          <img
            src={`${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`}
          />
        ) : (
          <Image
            width={200}
            style={{ display: "none" }}
            preview={{
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    // onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <Space size={12} className="toolbar-wrapper">
                  {/* <LeftOutlined onClick={() => onActive?.(-1)} /> */}
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                  {/* <DownloadOutlined onClick={handleDownload} /> */}

                  <UndoOutlined onClick={onReset} />
                </Space>
              ),
              visible: true,
              scaleStep: true,
              src: `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`,
              onVisibleChange: () => {
                closeModal();
              },
            }}
          />
        )}
      </>
    );
  } else if (selectedMedia?.format?.includes("video")) {
    return (
      <>
        {isPublicDisplay ? (
          <video
            muted
            style={{ width: "100%" }}
            controls
            src={`${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`}
          />
        ) : (
          <CommonViewModal
            visible={true}
            onOk={closeModal}
            onCancel={closeModal}
            content={
              <video
                muted
                style={{ width: "100%" }}
                controls
                src={`${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`}
              />
            }
          />
        )}
      </>
    );
  } else if (selectedMedia?.format?.includes("audio")) {
    return (
      <>
        {isPublicDisplay ? (
          <>
            <div className="audioDiv">
              <img src={audio}></img>
              <audio
                muted
                controls
                src={`${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`}
              />
            </div>
          </>
        ) : (
          <CommonViewModal
            visible={true}
            onOk={closeModal}
            onCancel={closeModal}
            content={
              <div className="audioDiv">
                <img src={audio}></img>
                <audio
                  muted
                  controls
                  src={`${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id || selectedMedia.shareable_id}`}
                />
              </div>
            }
          />
        )}
      </>
    );
  } else {
    if (isPublicDisplay) {
      return (
        <div className="unpreviewable">
          <div className="unpreviewable_image">
            <div>
              <img src={image} alt="" />
            </div>
          </div>
          <h4>Preview not available for this file type</h4>
        </div>
      );
    }
    closeModal();
    window.open(
      `${env.ipfsUrl}/${selectedMedia?.ipfs_url}/${selectedMedia?.id ?? selectedMedia?.shareable_id}`,
      "_blank",
      "noopener",
    );
  }
};

export default MediaDisplay;

MediaDisplay.propTypes = {
  isPublicDisplay: PropTypes.bool,
  selectedMedia: PropTypes.object.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};
