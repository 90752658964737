// import { useState, useEffect } from "react";

// function useDebounce(value, delay, isEnterPressed = false) {
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(() => {
//     if (isEnterPressed) {
//       setDebouncedValue(value); // Set the value immediately on Enter press
//     } else {
//       const handler = setTimeout(() => {
//         setDebouncedValue(value);
//       }, delay);

//       return () => {
//         clearTimeout(handler);
//       };
//     }
//   }, [value, delay, isEnterPressed]);

//   return debouncedValue;
// }

// export default useDebounce;

import { useState, useEffect } from "react";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
