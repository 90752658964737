import React from "react";
import "./SelectCard.scss";
import { ArrowIcon, UserIcon } from "../../../Assets/Assets";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
function SelectCard({ title, subtitle, description, linkTo }) {
  return (
    <div className="selectCard">
      <h5>
        <UserIcon /> <span>{title}</span>
      </h5>
      <h4>{subtitle}</h4>
      <p>{description}</p>
      <Link to={linkTo} className="selectCard_goNext">
        <span>Continue </span> <ArrowIcon />
      </Link>
    </div>
  );
}

SelectCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
};

export default SelectCard;
