import { React } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
function InfiniteScrollWrapper({
  lengthData,
  functionNext,
  children,
  hasMore,
}) {
  return (
    <InfiniteScroll
      dataLength={lengthData}
      next={functionNext}
      hasMore={hasMore}
      scrollableTarget="scroll-target"
      className="scroll-hide"
      loader={<div className="loader"></div>}
    >
      {children}
    </InfiniteScroll>
  );
}

InfiniteScrollWrapper.propTypes = {
  lengthData: PropTypes.number,
  functionNext: PropTypes.func,
  children: PropTypes.node,
  hasMore: PropTypes.bool,
};

export default InfiniteScrollWrapper;
