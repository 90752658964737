import React, { useState } from "react";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import LoginEmailSec from "../../Common/Components/LoginEmailSec/LoginEmailSec";

function BusinessAccount() {
  const [businessAccountObj, setBusinessAccountObj] = useState({
    fullName: "",
    companyName: "",
    functionalArea: "",
    jobRole: "",
    employeeCount: "",
  });

  const handleChange = (change) => {
    setBusinessAccountObj(change);
  };
  return (
    <div className="personalAccount">
      <InputCustom
        regularInput
        placeholder="Enter here"
        label
        onChange={(e) =>
          handleChange({ ...businessAccountObj, fullName: e.target.value })
        }
        labletext="Full Name"
        required
      />
      <InputCustom
        regularInput
        placeholder="Enter here"
        onChange={(e) =>
          handleChange({ ...businessAccountObj, companyName: e.target.value })
        }
        label
        labletext="Company Name"
        required
      />
      <div className="personalAccount_selector">
        <CustomSelect
          label
          labelText="Functional Area"
          required
          onChange={(e) =>
            handleChange({ ...businessAccountObj, functionalArea: e })
          }
          defaultValue="Select here"
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "yiminghe", label: "Yiminghe" },
            { value: "disabled", label: "Disabled", disabled: true },
          ]}
        />

        <CustomSelect
          label
          labelText="Job Role"
          required
          onChange={(e) => handleChange({ ...businessAccountObj, jobRole: e })}
          defaultValue="Select here"
          options={[
            { value: "apple", label: "Apple" },
            { value: "banana", label: "Banana" },
            { value: "orange", label: "Orange" },
            { value: "grape", label: "Grape" },
          ]}
        />
      </div>
      <CustomSelect
        label
        labelText="Number of Emplyees"
        required
        onChange={(e) =>
          handleChange({ ...businessAccountObj, employeeCount: e })
        }
        defaultValue="Select here"
        options={[
          { value: "apple", label: "Apple" },
          { value: "banana", label: "Banana" },
          { value: "orange", label: "Orange" },
          { value: "grape", label: "Grape" },
        ]}
      />
      <LoginEmailSec />
    </div>
  );
}

export default BusinessAccount;
