import React from "react";
import "././NoData.scss";
import PropTypes from "prop-types";

function NoData({
  message = "No Data Found",
  NotFound,
  icon,
  className,
  textColor,
  customStyle,
}) {
  return (
    <div className={`no-data-container ${className}`} style={customStyle}>
      {icon && (
        <div className="no-data-icon">
          <img src={NotFound} alt="" />
        </div>
      )}
      {textColor ? (
        <p className="no-data-message" style={{ color: textColor }}>
          {message}
        </p>
      ) : (
        <p className="no-data-message">{message}</p>
      )}
    </div>
  );
}

NoData.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  NotFound: PropTypes.string,
  textColor: PropTypes.string,
  customStyle: PropTypes.object,
};

export default NoData;
