/*eslint-disable*/
import "./DashCard.scss";
import React, { useState } from "react";
import "intro.js/introjs.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../../Assets/Assets";
import "../../../Pages/Dashboard/Dashboard.scss";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { setContainerSharedTabVisible } from "../../../store/slices/userSlice";
// import { setContainerFromDashBoard } from "../../../store/slices/uiSettingSlice";
import { handleDashboardRedirect } from "../../../store/slices/containerSlice";

function ShowContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [steps, setSteps] = useState([
    {
      stepNumber: "01",
      title: "Create Container",
      description:
        "Easily create and manage your containers with just a few taps. Start by adding your container!",
      buttons: [
        {
          label: "Create Container",
          icon: <ArrowIcon />,
          className: "target-button",
        },
      ],
      target: "#create-container-button",
    },
    {
      stepNumber: "02",
      title: "Upload File",
      description:
        "Easily add your files! Select files from your device and upload them directly into your container.",
      buttons: [{ label: "Upload Files", icon: <ArrowIcon /> }],
      target: "#upload-file-button",
    },
  ]);

  const handleClick = (button) => {
    // dispatch(setContainerFromDashBoard(true));
    dispatch(setContainerSharedTabVisible(false));
    navigate(Path.FILE_MANAGEMENT);

    if (button?.label === "Create Container") {
      handleDashboardRedirect(true);
    }
  };

  return (
    <div className="dashCardWrapper">
      {steps.map((step, index) => (
        <div className="dashCard" key={index} id={step.target.substring(1)}>
          <div className="dashCard_inner commonCardBg">
            <div className="dashCard_inner_left">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
            <div className="dashCard_inner_right">
              {step.buttons.map((button, buttonIndex) => (
                <ButtonCustom
                  regularBtn
                  key={buttonIndex}
                  label={button.label}
                  icon={button.icon}
                  onClick={() => handleClick(button)}
                  className={button?.className}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ShowContainer;
