import React from "react";
import PropTypes from "prop-types";
import "./UploadPopup.scss";
import { CrossIcon2, TickIcon } from "../../../Assets/Assets";
import imgPath from "../../../Assets/images/fileIcon.svg";
import LoadingLine from "../../LoadingLine/LoadingLine";
import { Tooltip } from "antd";
import { formatString } from "../../../Utils/helpers";
import { FILE_UPLOAD_STATUS } from "../../../constants";

function UploadSec({ title, UploadStatus, containerName, progress, onClose }) {

  return (
    <div className="uploadSec">
      <div>
        <div className="uploadSec_top">
          <img width="50px" src={imgPath} />

          <Tooltip placement="top" title={title.length > 35 ? title : ""}>
            <h4 className="ellipsis">
              {title?.length > 35 ? formatString(title, 17, 17) : title}
            </h4>
          </Tooltip>
        </div>
        <div className="progressBar">
          {progress === FILE_UPLOAD_STATUS.IN_PROGRESS || progress === FILE_UPLOAD_STATUS.IN_QUEUE  ? (
            <LoadingLine progress={progress} />
          ) : (
            <></>
          )}
        </div>
        <div>
          <div className="uploadSec_bottom">
            <p>
              {UploadStatus === "done"
                ? `Uploaded to ${containerName}`
                : UploadStatus === "inprogress" || UploadStatus === "inQueue"
                  ? "Uploading"
                  : "Failed upload"}
            </p>
          </div>
        </div>
      </div>

      <div>
        <h4>
          {UploadStatus === FILE_UPLOAD_STATUS.DONE ? (
            <TickIcon />
          ) : UploadStatus === FILE_UPLOAD_STATUS.IN_QUEUE ||
            UploadStatus === FILE_UPLOAD_STATUS.REJECTED ? (
            <span className="cursor-pointer" onClick={onClose}>
              <CrossIcon2 />
            </span>
          ) : (
            ""
          )}
          {/* <span className="cursor-pointer" onClick={onClose}>
            <CrossIcon2 />
          </span> */}
        </h4>
      </div>
    </div>
  );
}

UploadSec.propTypes = {
  title: PropTypes.string,
  containerName: PropTypes.string,
  progress: PropTypes.number,
  onClose: PropTypes.func,
  UploadStatus: PropTypes.string,
};

export default UploadSec;
