import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";

// Thunks to interact with the API
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async ({ csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${API_URL.GET_NOTIFICATION}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
        null,
      );

      if (response.code === HTTP_STATUS_CODE.OK) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  },
);

export const updateNotificationStatus = createAsyncThunk(
  "notifications/updateNotificationStatus",
  async ({ notificationId, csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${API_URL.UPDATE_NOTIFICATION}/${notificationId}`,
        HTTP_METHOD.PUT,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
        null,
      );
      if (response.code === HTTP_STATUS_CODE.OK) {
        return notificationId;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    updateNotification: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notifications = action.payload;
        state.updateNotification = action.payload;
        state.loading = false;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateNotificationStatus.fulfilled, (state, action) => {
        const updatedNotification = action.payload;
        state.updateNotification = state.notifications.filter(
          (notification) => notification.id !== updatedNotification,
        );
      });
  },
});

export default notificationSlice.reducer;
