import { isAddress } from "ethers";
import React from "react";
import toast from "react-hot-toast";
import { HTTP_METHOD, OPERATIONS } from "../constants";
import httpRequest from "./httpsRequest";
import moment from "moment/moment";

import { env } from "../constants/env";
import fileIcon from "../Assets/images/fileIcon.svg";
import videoIcon from "../Assets/images/videoIcon.svg";
import audioIcon from "../Assets/images/audioIcon.svg";
import Box from "../Assets/images/box.png";

import {
  faTrash,
  faPenToSquare,
  faCircleInfo,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FolderIcon, FolderICon, ShareIcon } from "../Assets/Assets";
import { Tooltip } from "antd";

export const getImage = (format, ipfsUrl, mediaId) => {
  let img;
  img = fileIcon;
  if (!format) {
    img = Box;
  } else if (format?.includes("image")) {
    if (format === "image/svg+xml") {
      img = fileIcon;
    } else {
      img = `${env.ipfsUrl}/${ipfsUrl}/${mediaId}`;
    }
  } else if (format?.includes("video")) {
    img = videoIcon;
  } else if (format?.includes("audio")) {
    img = audioIcon;
  }
  return img;
};

  export const formatNumber=(number)=> {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return number.toString();
}

export const convertDate = (isoString) => {
  const date = new Date(isoString);
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date
    .toLocaleString("en-US", options)
    .replace(",", " | ");
  return formattedDate;
};

export const extractFileName = (filePath) => {
  if (!filePath) return "-";
  const file = filePath.includes("/") ? filePath.split("/").pop() : filePath;
  return file.charAt(0).toUpperCase() + file.slice(1);
};

export const formatStorageSize = (bytes) => {
  if (bytes === undefined || bytes === null || isNaN(bytes))
    return "Unknown size";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

let genericData;
const dataType = ["Bytes", "KB", "MB", "GB", "TB"];
export const bytesToGenericUnit = (data, index = 0) => {
  if (data == 0) return { data, type: dataType[index] };

  genericData = data / 1024;
  if (genericData > 1) {
    index++;
    return bytesToGenericUnit(genericData, index);
  } else {
    const maxData = genericData * 1024;
    return { maxData, type: dataType[index] };
  }
};

export const YAxisMaxValue = (value) => {
  const valueStr = value.toString();
  const length = valueStr.length;
  if (length == 0) return 0;
  const firstDigit = parseInt(valueStr[0]) + 1;
  const ceilingValue = firstDigit * Math.pow(10, length - 1);
  return ceilingValue;
};

export const dateToWeek = (dateString) => {
  let dateObject = new Date(dateString);
  return dateObject.toLocaleString("en-us", { weekday: "long" });
};

export const displayDates = (dateString, period) => {
  return period == "month"
    ? moment(dateString).format("DD MMM")
    : moment(dateString).format("LT");
};

export const convertBytesToSpecific = (bytes, type) => {
  if (bytes == 0) return 0;

  switch (type) {
    case "Bytes": {
      return bytes;
    }
    case "KB": {
      return (bytes / 1024).toFixed(2);
    }
    case "MB": {
      return (bytes / (1024 * 1024)).toFixed(2);
    }
    case "GB": {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2);
    }
  }
};

export function capitalizeFirstLetter(str) {
  if (!str) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatString(str = "", startLen = 7, endLen = 7) {
  if (!str) return str; // Handle empty strings
  return `${str.substring(0, startLen)}...${str.substring(str?.length - endLen, str?.length)}`;
}

export function formatName(str = "") {
  if (!str) return str;
  return str?.length > 12
    ? `${str.substring(0, 4)}...${str.substring(str?.length - 4, str?.length)}`
    : str;
}

function getFileType(mimeType) {
  const parts = mimeType.split("/");
  return parts.length > 1 ? parts[1] : null; // Return the file type or null if not found
}

export const convertDateFormat = (dateString) => {
  const cleanedString = dateString.replace("|", "").trim();
  const [datePart, timePart] = cleanedString.split(" at ");
  const date = new Date(`${datePart} ${timePart}`);

  if (isNaN(date.getTime())) {
    console.error("Invalid date format");
    return null; // Return null for invalid dates
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:00`;
};

const storageUnits = {
  GB: 1e9,
  MB: 1e6,
  KB: 1e3,
  B: 1,
};

export const convertToBytes = (storage) => {
  const [value, unit] = storage.split(" ");
  const numericValue = parseFloat(value);

  return numericValue * (storageUnits[unit] || 0);
};

export const downloadMedia = async (url, format, handleLogOut = null, name) => {
  try {
    const response = await httpRequest(
      url,
      HTTP_METHOD.GET,
      null,
      null,
      "arraybuffer",
      handleLogOut,
    );

    toast.loading("Downloading");

    if (!response.error) {
      const imageBlob = new Blob([response], { type: format });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(imageBlob);
      let fileName = `downloaded-${+new Date()}.${getFileType(format)}`;
      link.download = name ? name : fileName;
      link.click();
      URL.revokeObjectURL(link.href);
      toast.dismiss();
      toast.success("Downloaded");
      // return response
    } else {
      toast.dismiss();
      toast.error("Downloading failed");
      console.error("Error fetching image:", response.error);
    }
  } catch (error) {
    toast.error("Resource does not exist");
    console.error("Error downloading the media:", error);
  }
};

export const copy = (string) => {
  if (string) {
    window.navigator.clipboard.writeText(string);
  }
  return;
};

export const validateAddress = (value = "", selfAddress) => {
  if (!value) return "";
  if (!value.startsWith("0x")) {
    return "Invalid wallet address. It must start with '0x'.";
  } else if (value.length !== 42) {
    return "Invalid wallet address length. It must be 42 characters long.";
  } else if (value === selfAddress) {
    return "Owner can't share data themselves";
  } else if (!isAddress(value)) {
    return "Invalid wallet address.";
  } else {
    return "";
  }
};

export const createColumns = (handleFolderClick) => [
  {
    title: ["Name"],
    dataIndex: "container",
    key: "container",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return nameA.localeCompare(nameB);
    },
    width: "20%",
  },
  {
    title: ["Files"],
    dataIndex: "files",
    key: "files",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => a.files - b.files,
    width: "20%",
  },
  {
    title: ["Storage"],
    dataIndex: "storage",
    key: "storage",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      return convertToBytes(a.storage) - convertToBytes(b.storage);
    },
    width: "20%",
  },
  {
    title: ["Last Updated"],
    dataIndex: "dateUpdated",
    key: "dateUpdated",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      return (
        new Date(convertDateFormat(a.dateUpdated)) -
        new Date(convertDateFormat(b.dateUpdated))
      );
    },
    width: "20%",
  },
  {
    title: <div className="textAlignCenter">Action</div>,
    dataIndex: "blankIcon",
    key: "blankIcon",
    width: "20%",
  },
];

export const getItems = (data, handleOperations) => [
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.open)}
      >
        <FolderICon /> Open Container
      </span>
    ),
    key: "0",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.edit)}
      >
        <FontAwesomeIcon icon={faPenToSquare} /> Rename Container
      </span>
    ),
    key: "1",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.share)}
      >
        <ShareIcon /> Share Container
      </span>
    ),
    key: "2",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.details)}
      >
        <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
        Container Details
      </span>
    ),
    key: "3",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.delete)}
      >
        <FontAwesomeIcon icon={faTrash} />
        Delete Container
      </span>
    ),
    key: "4",
  },
];

export const Foldercolumns = (handleFolderNavigation) => [
  {
    title: ["Name"],
    dataIndex: "folderName",
    key: "folderName",
    onCell: (record) => ({
      onClick: () => handleFolderNavigation(record),
    }),
    sorter: (a, b) => {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return nameA.localeCompare(nameB);
    },
    width: "20%",
  },
  {
    title: <span>Type</span>,
    dataIndex: "format",
    key: "format",
    render: (data) => (
      <p className="dFlex">
        <span className="ellipsis">
          {data ? (
            extractFileName(data).length > 10 ? (
              <Tooltip title={extractFileName(data)} placement="topLeft">
                <span>{formatString(extractFileName(data), 6, 6)}</span>
              </Tooltip>
            ) : (
              extractFileName(data)
            )
          ) : (
            "Folder"
          )}
        </span>
      </p>
    ),
    onCell: (record) => ({
      onClick: () => handleFolderNavigation(record),
    }),
    sorter: (a, b) => {
      const typeA = a.format ? extractFileName(a.format) : "Folder";
      const typeB = b.format ? extractFileName(b.format) : "Folder";
      return typeA.localeCompare(typeB);
    },
    width: "20%",
  },
  {
    title: <span>Size</span>,
    dataIndex: "storage",
    key: "storage",
    onCell: (record) => ({
      onClick: () => handleFolderNavigation(record),
    }),
    sorter: (a, b) => {
      return convertToBytes(a.storage) - convertToBytes(b.storage);
    },
    width: "20%",
  },
  {
    title: <span>Last Updated</span>,
    dataIndex: "dateUpdated",
    key: "dateUpdated",
    sorter: (a, b) => {
      return (
        new Date(convertDateFormat(a.dateUpdated)) -
        new Date(convertDateFormat(b.dateUpdated))
      );
    },
    width: "20%",
  },
  {
    title: <div className="textAlignCenter">Action</div>,
    dataIndex: "blankIcon",
    key: "blankIcon",
    width: "20%",
  },
];

// getItems.js

export const getItemsfolder = (
  data,
  handleOperations,
  firstFolderChain,
  OPERATIONS,
) => {
  const itemType = !data.format ? "Folder" : "File";

  if (itemType === "Folder") {
    return [
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.open)}
          >
            <FolderIcon /> Open {itemType}
          </span>
        ),
        key: "0",
      },
      {
        label:
          firstFolderChain?.container_access_level === "manage" ||
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} className="item_fa" />{" "}
              Rename {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "1",
      },
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.details, itemType)}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
            &nbsp; {itemType} Details
          </span>
        ),
        key: "2",
      },
      {
        label:
          firstFolderChain?.container_access_level === "manage" ||
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() =>
                handleOperations(data, OPERATIONS.delete, "folder")
              }
            >
              <FontAwesomeIcon icon={faTrash} className="item_fa" />
              &nbsp; Delete {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "3",
      },
    ];
  } else {
    return [
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, "open")}
          >
            <FolderIcon /> Open
          </span>
        ),
        key: "0",
      },
      {
        label:
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, "share", "file")}
            >
              <ShareIcon /> Share {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "1",
      },
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, "details", itemType)}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
            {itemType} Details
          </span>
        ),
        key: "2",
      },
      {
        label:
          firstFolderChain?.container_access_level === "manage" ||
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, "delete", "file")}
            >
              <FontAwesomeIcon icon={faTrash} className="item_fa" />
              Delete {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "3",
      },
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.download)}
          >
            <FontAwesomeIcon icon={faDownload} className="item_fa" />
            Download {itemType}
          </span>
        ),

        key: "4",
      },
    ];
  }
};
