import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  name: null,
  containerSharedTabVisible: false,
  userId: null,
  planType: null,
  bannerShow: true,
  showContainerModal: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { email, name, id, plan_type } = action.payload;
      state.email = email;
      state.name = name;
      state.userId = id;
      state.planType = plan_type ?? null;
    },
    setContainerSharedTabVisible: (state, action) => {
      state.containerSharedTabVisible = action.payload;
    },
    setPlanType: (state, action) => {
      state.planType = action.payload;
    },
    setBanner: (state, action) => {
      state.bannerShow = action.payload;
    },
    setContainerModal: (state, action) => {
      state.showContainerModal = action.payload;
    },
  },
});
export const {
  setUserData,
  setContainerSharedTabVisible,
  setPlanType,
  setBanner,
  setContainerModal,
} = userSlice.actions;
export const selectUserEmail = (state) => state.user.email;
export default userSlice.reducer;
