import React from "react";
import "./launchModal.scss";
import PropTypes from "prop-types";
import CommonModal from "../../Common/CommonModal/CommonModal";
import loginBanner from "../../Assets/images/LoginBanner.png";

function LaunchModal({ show, handleClick, closeModal }) {
  return (
    <>
      <CommonModal
        className="launchModal"
        visible={show}
        onClick={handleClick}
        content={
          <>
            <img className="loginBannerImg" src={loginBanner} alt="" />
            <h1>
              Welcome to <span>tomi Storage!</span>
            </h1>
            <p>
              We re excited to offer you early access to explore our platform
              and get a feel for its features. Please mind that during this
              initial phase, files you upload will be accessible to anyone with
              the link to them. We recommend using the platform thoughtfully and
              keeping backups of your important files.
            </p>
          </>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />
    </>
  );
}

export default LaunchModal;

LaunchModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  handleClick: PropTypes.func,
};
