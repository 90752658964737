import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import logo from "../../../Assets/images/HeaderLogo.png";
import "./OnboardAuthLayout.scss";
import LoginBanner from "../../../Assets/images/LoginBanner.png";
import { Path } from "../../../Routing/Constant/RoutePaths";
import PublicShare from "../../../Pages/PublicShare/PublicShare";
import Footer from "../../Components/Footer/Footer";
// import { ArrowLeftOutlined } from "@ant-design/icons";

function OnboardAuthLayout() {
  const location = useLocation();
  // function isFilePresent(str) {
  //   const filePattern = /\/file\/zb[1-9a-km-zA-HJ-NP-Z]{47}/;
  //   return filePattern.test(str);
  // }
  const currentLocation = location.pathname;
  const isPublicSharePage = currentLocation == "/file";

  // useEffect(() => {
  //   checkMetaMaskAccount();
  // }, []);

  // const checkMetaMaskAccount = async () => {
  //   const handleAccountsChanged = (accounts) => {
  //     if (accounts.length === 0) {
  //       window.location.reload();
  //     }
  //   };

  //   if (window.ethereum) {
  //     const provider = window.ethereum;

  //     provider.on("accountsChanged", handleAccountsChanged);

  //     return () => {
  //       provider.removeListener("accountsChanged", handleAccountsChanged);
  //     };
  //   } else {
  //     console.error("MetaMask provider not found.");
  //   }
  // };

  return (
    <>
      <div className="loginBoard">
        {isPublicSharePage ? (
          <PublicShare />
        ) : (
          <>
            {currentLocation === Path.ACCOUNT_TYPE ? null : (
              <div className="loginBoard_left">
                <img src={LoginBanner} alt="" />
              </div>
            )}
            <div className="loginBoard_right">
              <div className="loginBoard_right_content">
                <div className="loginBoard_right_header">
                  {/* {currentLocation === Path.FORGOT_PASSWORD && (
              <Link to={Path.LOGIN}> */}
                  {/* <h4 className="loginBoard_right_heading">
                <ArrowLeftOutlined />
              </h4> */}
                  {/* </Link>
            )} */}
                  {/* <Link to={Path.WELCOME}> */}{" "}
                  {/* {currentLocation === Path.FORGOT_PASSWORD && (
                  <Link to={Path.WELCOME}>
                    <NextArrow />
                  </Link>
                )} */}
                  <img src={logo} alt="" />
                  {/* </Link> */}
                </div>
                <p>
                  {currentLocation === Path.WELCOME &&
                    "Securely store, manage and share data"}
                  {currentLocation === Path.PERSONAL_ACCOUNT &&
                    "Great, Almost There!"}
                  {currentLocation === Path.BUSINESS_ACCOUNT &&
                    "Great, Almost There!"}
                  {/* {currentLocation === Path.FORGOT_PASSWORD &&
                  "Can't Access Your Account? Let's Help You Recover It."} */}
                </p>

                <h4 className="loginBoard_right_heading">
                  {currentLocation === Path.SELECT_ACCOUNT &&
                    "START SETTING UP YOUR ACCOUNT"}
                  {currentLocation === Path.PERSONAL_ACCOUNT &&
                    "Create Your Account"}
                  {currentLocation === Path.BUSINESS_ACCOUNT &&
                    "BUSINESS ACCOUNT"}
                  {currentLocation === Path.FORGOT_PASSWORD &&
                    "Forgot Account?"}
                </h4>
                <p className="loginBoard_right_paragraph">
                  {currentLocation === Path.SELECT_ACCOUNT &&
                    "Select your account type to customize your tstorage experience"}
                  {currentLocation === Path.PERSONAL_ACCOUNT &&
                    "To set up your account on Tomi, please fill in the required fields."}
                  {currentLocation === Path.BUSINESS_ACCOUNT &&
                    "Lorem ipsum dolor sit amet consectetur."}
                  {currentLocation === Path.FORGOT_PASSWORD &&
                    "To recover your account, please enter your Email address below."}
                </p>
                {currentLocation === Path.WELCOME && (
                  <div>
                    <h2>
                      Welcome To <br /> <span>STORAGE</span>
                    </h2>
                  </div>
                )}
                <Outlet />
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default OnboardAuthLayout;
