import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
  INFINITE_SCROLLBAR,
} from "../../constants";
import httpRequest from "../../Utils/httpsRequest";
import toast from "react-hot-toast";

const initialState = {
  loading: false,
  error: null,
  sharedItems: [],
};

export const shareItem = createAsyncThunk(
  "sharing/shareItem",
  async (
    {
      wallet_address,
      shareable_id,
      shareable_type,
      access_level,
      access_type,
      handleLogOut,
      csrfToken,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await httpRequest(
        API_URL.SHARE_ITEM,
        HTTP_METHOD.POST,
        {
          wallet_address,
          shareable_id,
          shareable_type,
          access_level,
          access_type,
        },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        toast.error(response?.error?.message);
        return rejectWithValue(
          response?.error?.message || "Failed to Share container",
        );
      }
    } catch (error) {
      return rejectWithValue(error?.message || "Something went wrong");
    }
  },
);

export const fetchContainerSharedWithMe = createAsyncThunk(
  "sharing/fetchSharedItems",
  async (
    {
      searchTerm = "",
      page = INFINITE_SCROLLBAR.DEFAULT_PAGE,
      limit = INFINITE_SCROLLBAR.DEFAULT_LIMIT,
      prevData = null,
      handleLogOut,
      csrfToken,
    },
    { rejectWithValue },
  ) => {
    try {
      let isMoreData = false;
      const response = await httpRequest(
        `${API_URL.SHARE_CONTAINER_WITH_ME}?search=${encodeURIComponent(searchTerm?.searchTerm)}&page=${page}&limit=${limit}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        let sharedData = response?.data;
        let data;
        if (page == 1) {
          data = sharedData;
        } else {
          prevData?.length > 0
            ? (data = [...prevData, ...sharedData])
            : (data = sharedData);
        }
        if (sharedData?.length == limit) {
          isMoreData = true;
        }
        return { isMoreData, data };
      } else {
        return rejectWithValue(
          response?.error?.message || "Failed to Fetch Share container",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const fetchContainerSharedByMe = createAsyncThunk(
  "sharing/fetchContainerSharedByMe",
  async (
    {
      searchTerm = "",
      page = INFINITE_SCROLLBAR.DEFAULT_PAGE,
      limit = INFINITE_SCROLLBAR.DEFAULT_LIMIT,
      prevData = null,
      handleLogOut,
      csrfToken,
    },
    { rejectWithValue },
  ) => {
    try {
      let isMoreData = false;
      const response = await httpRequest(
        `${API_URL.SHARE_CONTAINER_BY_ME}?search=${encodeURIComponent(searchTerm)}&page=${page}&limit=${limit}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        let sharedData = response?.data;
        let data;

        if (page == 1) {
          data = sharedData;
        } else {
          prevData?.length > 0
            ? (data = [...prevData, ...sharedData])
            : (data = sharedData);
        }
        if (sharedData?.length == limit) {
          isMoreData = true;
        }
        return { isMoreData, data };
      } else {
        return rejectWithValue(
          response?.error?.message || "Failed to Fetch Share container",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const removeMember = createAsyncThunk(
  "members/removeMember",
  async (
    { member_id, shareable_id, handleLogOut, csrfToken },
    { rejectWithValue },
  ) => {
    try {
      const response = await httpRequest(
        `${API_URL.REMOVE_MEMBER}${member_id}/${shareable_id}`,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
        null,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response?.message;
      } else {
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const changeRole = createAsyncThunk(
  "members/changeAccessType",
  async (
    { member_id, access_level, shareable_id, handleLogOut, csrfToken },
    { rejectWithValue },
  ) => {
    try {
      const response = await httpRequest(
        `${API_URL.CHANGE_ROLE}`,
        HTTP_METHOD.PUT,
        {
          member_id,
          shareable_id,
          access_level,
        },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
        null,
      );
      console.log("response: ", response);
      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response?.message;
      } else {
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const fetchSharedMedia = createAsyncThunk(
  "/member/shared-resources",
  async (
    {
      shareable_id = "",
      page = INFINITE_SCROLLBAR.DEFAULT_PAGE,
      limit = INFINITE_SCROLLBAR.DEFAULT_LIMIT,
      prevData = null,
      handleLogOut,
      csrfToken,
    },
    { rejectWithValue },
  ) => {
    try {
      let isMoreSharedMembers = false;
      const response = await httpRequest(
        `${API_URL.GET_SHARED_MEDIA}${shareable_id}?page=${page}&limit=${limit}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        let sharedMembersWrtMedia = response?.data;
        let data;
        if (page == 1) {
          data = sharedMembersWrtMedia;
        } else {
          prevData?.length > 0
            ? (data = [...prevData, ...sharedMembersWrtMedia])
            : (data = sharedMembersWrtMedia);
        }
        if (sharedMembersWrtMedia?.length == limit) {
          isMoreSharedMembers = true;
        }
        return { isMoreSharedMembers, data };
      } else {
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const fetchIsPubliclyShared = createAsyncThunk(
  "/member/",
  async (
    { shareable_id = "", handleLogOut, csrfToken },
    { rejectWithValue },
  ) => {
    try {
      const response = await httpRequest(
        `${API_URL.IS_PUBLICLY_SHARED}${shareable_id}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        const isPublic = response.data.is_public;

        return { isPublic };
      } else {
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const unSharePublicly = createAsyncThunk(
  "/member/unshare/",
  async (
    { shareable_id = "", handleLogOut, csrfToken },
    { rejectWithValue },
  ) => {
    try {
      const response = await httpRequest(
        `${API_URL.UNSHARE_PUBLICLY}${shareable_id}`,
        HTTP_METHOD.PUT,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

// Slice definition
const shareingSlice = createSlice({
  name: "shareing",
  initialState,
  reducers: {
    clearShareState: (state) => {
      state.initialState = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Share an item (container, folder, media)
      .addCase(shareItem.pending, (state) => {
        state.loading = false;
        state.status = "sharing";
        state.error = null;
      })
      .addCase(shareItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.loading = false;
        const newSharedItem = {
          ...action.payload.data,
          shareable_name: action.payload.data.resource_name,
          name: action.payload.data.grantee_user_name,
        };
        //    console.log("newSharedItem:",newSharedItem)
        state.sharedItems = [newSharedItem, ...state.sharedItems];
      })
      .addCase(shareItem.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      //Fetch shared items
      .addCase(fetchContainerSharedWithMe.pending, (state, action) => {
        if (action?.meta?.arg?.page == 1) {
          state.loading = true;
          state.status = "loading";
          state.error = null;
        }
      })
      .addCase(fetchContainerSharedWithMe.fulfilled, (state, action) => {
        state.loading = false;
        state.sharedItems = action.payload.data;
        state.isMoreData = action.payload.isMoreData;
        state.status = "succeeded";
      })
      .addCase(fetchContainerSharedWithMe.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      .addCase(fetchContainerSharedByMe.pending, (state, action) => {
        if (action?.meta?.arg?.page == 1) {
          state.loading = true;
          state.status = "loading";
          state.error = null;
        }
      })
      .addCase(fetchContainerSharedByMe.fulfilled, (state, action) => {
        state.loading = false;
        state.sharedItems = action.payload.data;
        state.isMoreData = action.payload.isMoreData;
        state.status = "succeeded";
      })
      .addCase(fetchContainerSharedByMe.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      // Remove shared menber
      .addCase(removeMember.pending, (state) => {
        state.loading = false;
        state.status = "removing";
        state.error = null;
      })
      .addCase(removeMember.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.payload = action.payload;
        state.sharedItems = state.sharedItems.filter(
          (item) => item.shareable_id !== action.meta.arg.shareable_id,
        );
      })
      .addCase(removeMember.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      //Change  role
      .addCase(changeRole.pending, (state) => {
        state.loading = false;
        state.status = "changing";
        state.error = null;
      })
      .addCase(changeRole.fulfilled, (state, action) => {
        console.log("state.sharedItems", state.sharedItems);

        state.loading = false;
        state.status = "succeeded";
        state.payload = action.payload;

        state.sharedItems = state.sharedItems.map((item) => {
          //console.log(item.member_id, action.meta.arg.member_id);
          if (
            item.shareable_id === action.meta.arg.shareable_id &&
            item.grantee_user_id === action.meta.arg.member_id
          ) {
            item.access_level = action.meta.arg.access_level;
          }
          return item;
        });
      })
      .addCase(changeRole.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      // fetch Shared Media
      .addCase(fetchSharedMedia.pending, (state, action) => {
        if (action?.meta?.arg?.page == 1) {
          state.loading = true;
          state.status = "loading";
          state.error = null;
        }
      })
      .addCase(fetchSharedMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.sharedMembersWrtMedia = action.payload.sharedMembersWrtMedia;
        state.isMoreSharedMembers = action.payload.isMoreSharedMembers;
        state.status = "succeeded";
      })
      .addCase(fetchSharedMedia.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      // fetch is Publicly Shared
      .addCase(fetchIsPubliclyShared.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchIsPubliclyShared.fulfilled, (state, action) => {
        state.loading = false;
        state.isPublic = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchIsPubliclyShared.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      })

      // unShare Publicly

      .addCase(unSharePublicly.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(unSharePublicly.fulfilled, (state) => {
        state.loading = false;
        state.status = "succeeded";
      })
      .addCase(unSharePublicly.rejected, (state, action) => {
        state.loading = false;
        state.status = "rejected";
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
      });
  },
});

export const { clearShareState } = shareingSlice.actions;
export default shareingSlice.reducer;
