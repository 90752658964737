import React from "react";
import "./ButtonCustom.scss";
import PropTypes from "prop-types";
import { Dropdown, Tooltip } from "antd";
function ButtonCustom(props) {
  const {
    label,
    onClick,
    type,
    className,
    regularBtn,
    grayBtn,
    borderBtn,
    disabled,
    tabIndex,
    children,
    id,
    icon,
    lefticon,
    smallBtn,
    smallborderBtn,
    dropDown,
    items,
    trigger,
    name,
    tooltip = "",
  } = props;
  return (
    <>
      {dropDown && items && trigger ? (
        <Dropdown
          menu={{
            items,
          }}
          trigger={trigger}
        >
          <label>
            <span
              className={`${className ? className : ""} customButton customButton-smallBtn`}
            >
              {<span className="customButton-icon">{children}</span>}
              <span className="custom-file">{label}</span>
            </span>
          </label>
        </Dropdown>
      ) : (
        <Tooltip title={tooltip}>
          <button
            className={`customButton ${className ? className : ""} ${
              regularBtn ? "customButton-regular" : ""
            } ${grayBtn ? "customButton-gray" : ""} ${borderBtn ? "customButton-borderBtn" : ""} ${smallborderBtn ? "customButton-smallborderBtn" : ""}${smallBtn ? "customButton-smallBtn" : ""}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
            tabIndex={tabIndex}
            id={id}
            name={name}
          >
            {lefticon && <span className="customButton-icon">{lefticon}</span>}
            {children && children} <p>{label}</p>
            {icon && <span className="customButton-icon">{icon}</span>}
          </button>
        </Tooltip>
      )}
    </>
  );
}

ButtonCustom.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  regularBtn: PropTypes.bool,
  grayBtn: PropTypes.bool,
  borderBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  children: PropTypes.node,
  id: PropTypes.string,
  icon: PropTypes.element,
  smallBtn: PropTypes.bool,
  smallborderBtn: PropTypes.bool,
  dropDown: PropTypes.bool,
  items: PropTypes.array,
  trigger: PropTypes.array,
  name: PropTypes.string,
  lefticon: PropTypes.element,
  tooltip: PropTypes.string,
};

export default ButtonCustom;
