export const Path = {
  WELCOME: "/",
  LOGIN: "/login/",
  SELECT_ACCOUNT: "/select-account/",
  PERSONAL_ACCOUNT: "/personal-account/",
  BUSINESS_ACCOUNT: "/business-account/",
  ACCOUNT_TYPE: "/select-plan",
  FORGOT_PASSWORD: "/forgot-password/",
  VERIFY_OTP: "/verify-otp/",
  DASHBOARD: "/dashboard",
  FILE_MANAGEMENT: "/file-management",
  FOLDER_MANAGE: "/folder-management/:type/:id",
  FILE_MANAGE_CARD_SEC: "/file-management/cards",
  ACCESSKEY: "/access-key",
  MEMBERS: "/members",
  DAO: "/dao",
  SETTINGS: "/settings",
  BILLING_INFORMATION: "/settings/billing-information",
  PLAN: "/upgrade-plan",
  NOTIFICATION: "/notification",
  FILE: "/file",
  CONTACTUS: "/report-media",
};
