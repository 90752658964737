import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const getName = (state) => state.settings.name;

export const { setName } = settingsSlice.actions;

export default settingsSlice.reducer;
