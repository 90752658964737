import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeFromBreadcrumb } from "../../../store/slices/breadcrumbSlice";
import "./CustomBreadcrumb.scss";
import { LeftArrow } from "../../../Assets/Assets";

const CustomBreadcrumb = ({ items, separatorIcon = "/" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState(null);
  const folderChain = useSelector((state) => state.breadcrumb.folderChain);
  const lastName =
    folderChain.length > 0 ? folderChain[folderChain.length - 1].name : "";
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 776);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (item, index) => {
    try {
      if (!item || index < 0 || index >= folderChain.length) {
        throw new Error("Invalid breadcrumb item or index.");
      }
      if (isMobile) {
        dispatch(removeFromBreadcrumb(index));
        if (index == 0) {
          navigate("/file-management");
        } else {
          navigate(item.id);
        }
      } else {
        if (item.href) {
          if (index === folderChain.length - 1) {
            console.warn("Already at the last breadcrumb. No action taken.");
            return;
          }

          dispatch(removeFromBreadcrumb(index));
          navigate(item.href);
          setError(null);
        } else {
          throw new Error("Breadcrumb item does not have a valid href.");
        }
      }
    } catch (err) {
      console.error("Error in breadcrumb navigation:", err);
      setError(
        err.message || "An error occurred while navigating. Please try again.",
      );
    }
  };

  return (
    <nav className="custom-breadcrumb">
      {error && <div className="error-message">{error}</div>}

      {isMobile ? (
        <>
          <span
            className="back-button"
            onClick={() => {
              if (folderChain.length > 1) {
                const previousItem = folderChain[folderChain.length - 2];
                handleClick(previousItem, folderChain.length - 2);
              } else {
                const previousItem = folderChain[folderChain.length - 1];
                handleClick(previousItem, folderChain.length - 1);
              }
            }}
          >
            <p>
              <LeftArrow /> Back
            </p>{" "}
            {lastName}
          </span>
        </>
      ) : (
        <>
          {items.map((item, index) => {
            const isFirst = index === 0;
            const isLast = index === items.length - 1;

            const breadcrumbClass = `breadcrumb-item ${item.href ? "clickable" : ""} 
        ${isFirst ? "breadcrumb-first" : ""} 
        ${isLast ? "breadcrumb-last" : ""}`;

            return (
              <span
                key={index}
                className={breadcrumbClass}
                onClick={() => handleClick(item, index)}
              >
                <span
                  className={breadcrumbClass}
                  style={{
                    textTransform: "capitalize",
                    color: isFirst ? "white" : isLast ? "#ff0083" : "gray",
                  }}
                >
                  {item.title}
                  {index < items.length - 1 && (
                    <span className="breadcrumb-separator">
                      {separatorIcon}
                    </span>
                  )}
                </span>
              </span>
            );
          })}
        </>
      )}
    </nav>
  );
};

CustomBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  separatorIcon: PropTypes.node,
};

export default CustomBreadcrumb;
