import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BTN_LABLE } from "../../../constants";
import { ArrowIcon } from "../../../Assets/Assets";
import InputCustom from "../InputCustom/InputCustom";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { setUserData } from "../../../store/slices/userSlice";
import { setConnectButton } from "../../../store/slices/uiSettingSlice";

function LoginEmailSec(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    errorMsg,
    disabled,
    resetState,
    stateValues,
    handleChange,
    handleSubmit,
    contextHolder,
  } = props;

  const handleBack = () => {
    resetState();
    navigate(Path.LOGIN);
    dispatch(setConnectButton(BTN_LABLE.DISCONNECT));
    dispatch(
      setUserData({
        email: "",
        name: "",
      }),
    );
  };

  return (
    <>
      <div className="personalAccount_email">
        {contextHolder}
        <InputCustom
          required
          name="email"
          regularInput
          label={true}
          labletext="Email"
          error={errorMsg}
          placeholder="Enter Email Address"
          onChange={handleChange}
          value={stateValues.email}
        />
      </div>
      <div className="personalAccount_btns">
        <ButtonCustom
          className="personalAccount_btns_back"
          label="Back"
          borderBtn
          onClick={handleBack}
          lefticon={<ArrowIcon />}
        />
        <ButtonCustom
          disabled={disabled}
          regularBtn
          label="Continue"
          icon={<ArrowIcon />}
          onClick={handleSubmit}
          className={disabled ? "disableBtn" : ""}
        />
      </div>
    </>
  );
}

LoginEmailSec.propTypes = {
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool,
  resetState: PropTypes.func,
  stateValues: PropTypes.object,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  contextHolder: PropTypes.any,
  setCaptchaToken: PropTypes.func,
};

export default LoginEmailSec;
