import { React } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
function InfiniteScrollWrapper({
  lengthData,
  functionNext,
  children,
  hasMore,
  isWrapperHeight,
}) {
  return isWrapperHeight ? (
    <InfiniteScroll
      dataLength={lengthData}
      next={functionNext}
      hasMore={hasMore}
      height={200}
      //  scrollableTarget="custom-body-id"
      loader={<div className="loader"></div>}
      // endMessage={<h2>You have seen it all</h2>}
    >
      {children}
    </InfiniteScroll>
  ) : (
    <InfiniteScroll
      dataLength={lengthData}
      next={functionNext}
      hasMore={hasMore}
      //  scrollableTarget="custom-body-id"
      loader={<div className="loader"></div>}
      // endMessage={<h2>You have seen it all</h2>}
    >
      {children}
    </InfiniteScroll>
  );
}

InfiniteScrollWrapper.propTypes = {
  lengthData: PropTypes.number,
  functionNext: PropTypes.func,
  children: PropTypes.node,
  hasMore: PropTypes.bool,
  isWrapperHeight: PropTypes.bool,
};

export default InfiniteScrollWrapper;
