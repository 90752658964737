import React from "react";
import "./CustomSelect.scss";
import PropTypes from "prop-types";
import { Select, Space } from "antd";
import NoData from "../../noData/NoData";
import NotFound from "../../../Assets/images/notFoundContainer.png";

function CustomSelect({
  className,
  label,
  labelText,
  required,
  borderSelect,
  options = [],
  defaultValue,
  value,
  onChange,
  allowMultiple = false,
  showSearch = false,
  description = "",
  isOpen,
}) {
  const MAX_COUNT = 3;
  return (
    <div
      className={`customSelect ${className} ${borderSelect ? "customSelect-border" : ""}`}
    >
      {label && (
        <label>
          {labelText}
          {required && <span>*</span>}
        </label>
      )}
      <Space direction="vertical" style={{ width: "100%" }}>
        <Select
          open={isOpen}
          mode={allowMultiple ? "multiple" : undefined}
          showSearch={showSearch}
          defaultValue={defaultValue}
          maxCount={MAX_COUNT}
          onChange={onChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          options={options.map(({ label, value, disabled }) => ({
            label,
            value,
            disabled,
          }))}
          style={{ width: "100%" }}
          value={value || defaultValue}
          placeholder={
            allowMultiple ? "Select multiple values" : "Select a value"
          }
          notFoundContent={
            <NoData
              className="selectNoData"
              icon
              NotFound={NotFound}
              message="No matching results were found!"
            />
          }
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
        {/* {selectedValue && selectedDescription && value && ( */}
        {description && <div className="descriptionDiv">{description}</div>}
        {/* )} */}
      </Space>
    </div>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.bool,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  borderSelect: PropTypes.bool,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  allowMultiple: PropTypes.bool,
  showSearch: PropTypes.bool,
  value: PropTypes.string,
  closeModal: PropTypes.func,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default CustomSelect;
