import React from "react";
import "./Login.scss";
import SelectCard from "../../Common/Cards/SelectCard/SelectCard";
import { Path } from "../../Routing/Constant/RoutePaths";

function SelectAccountScreen() {
  return (
    <div className="selectAccount">
      <SelectCard
        title="Personal"
        subtitle="I am using TStorage for Myself"
        description="Lorem ipsum dolor sit amet consectetur. Lacus nullam enim morbi magna nec curabitur tincidunt. Congue volutpat."
        linkTo={Path.PERSONAL_ACCOUNT}
      />
      {/* <SelectCard
        title="Business"
        subtitle="I am using TStorage for Business"
        description="Lorem ipsum dolor sit amet consectetur. Lacus nullam enim morbi magna nec curabitur tincidunt. Congue volutpat."
        linkTo={Path.BUSINESS_ACCOUNT}
      /> */}
    </div>
  );
}

export default SelectAccountScreen;
