import {
  setMainLoading,
  setWltSignature,
} from "../store/slices/uiSettingSlice";
import introJs from "intro.js";
import { useDispatch } from "react-redux";
import { logOut } from "../store/slices/authSlice";
import { useDisconnect } from "@web3modal/ethers/react";
import { clearShareState } from "../store/slices/shareSlice";
import { clearContainerState } from "../store/slices/containerSlice";

export default function useLogout() {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();

  const handleLogout = async () => {
    try {
      localStorage.clear("setUserIntroGuide");
      localStorage.clear("isReloaded");
      introJs().exit();
      dispatch(logOut());
      dispatch(setWltSignature(null));
      dispatch(clearContainerState());
      dispatch(clearShareState());
      dispatch(setMainLoading(false));
      await disconnect();
      return true;
    } catch (error) {
      console.log("error while logout", error);
      return false;
    }
  };

  return handleLogout;
}
