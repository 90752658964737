import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpRequest from "../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";

// AsyncThunk for fetching plans
export const fetchPlans = createAsyncThunk(
  "plans/fetchPlans",
  async ({ csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        API_URL.PLAN,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response.code === HTTP_STATUS_CODE.OK) {
        console.log("response data: ", response.data);
        return response.data;
      } else {
        return rejectWithValue("Failed to fetch plans");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  },
);

// Slice for managing plans state
const plansSlice = createSlice({
  name: "plans",
  initialState: {
    plans: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.plans = action.payload || []; // Ensure payload is an array
        state.loading = false;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.error = action.payload || "An unknown error occurred";
        state.loading = false;
      });
  },
});

export default plansSlice.reducer;
