import {
  convertDate,
  downloadMedia,
  extractFileName,
  formatStorageSize,
} from "../../Utils/helpers";
import "./PublicShare.scss";
import { env } from "../../constants/env";
// import { useDispatch } from "react-redux";
import NoLonger from "../NoLonger/NoLonger";
import httpRequest from "../../Utils/httpsRequest";
import { useLocation } from "react-router-dom";
import { DownloadIcon } from "../../Assets/Assets";
import React, { useEffect, useState } from "react";
// import { setMainLoading } from "../../store/slices/uiSettingSlice";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";
import HeaderCommon from "../../Common/Components/HeaderCommon/HeaderCommon";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import MediaDisplay from "../../Common/Components/MediaDisplay/MediaDisplay";
import { Skeleton } from "antd";
import ReportMediaModal from "../ReportMediaModal/ReportMediaModal";
function PublicShare() {
  // const dispatch = useDispatch();
  const [notFound, setNotFound] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [mediaDetails, setMediaDetails] = useState(null);
  const [show, setShow] = useState(false);
  const [contactDetail, setContactDetail] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    message: "",
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const ipfsId = query.get("ipfsId");
  const mediaId = query.get("mediaId");
  const [isLoading, setIsLoading] = useState(true);

  const getMediaDetails = async () => {
    try {
      const url = `${env.backendUrl}${API_URL.MEDIA_DETAILS}${ipfsId}/${mediaId}`;
      const response = await httpRequest(url, HTTP_METHOD.GET);
      if (response.code === HTTP_STATUS_CODE.OK) {
        setMediaDetails(response?.data);
        setIsModalOpen(true);
      } else {
        setMediaDetails(null);
        setNotFound(true);
      }
    } catch (error) {
      console.error("An unknown error occurred in public media details", error);
    } finally {
      setIsLoading(false);
      // dispatch(setMainLoading(false));
    }
  };
  useEffect(() => {
    getMediaDetails();
  }, [ipfsId, mediaId]);

  const handleDownload = async () => {
    setIsDisabled(true);
    const url = `${env.backendUrl}${API_URL.DOWNLOAD_PUBLIC_MEDIA}${ipfsId}/${mediaId}`;
    await downloadMedia(url, mediaDetails?.format);
    setIsDisabled(false);
  };

  const closeModal = () => {
    setShow(false);
    setContactDetail({
      firstName: "",
      lastName: "",
      emailId: "",
      message: "",
    });
  };
  const handleClick = () => {
    setShow(true);
  };
  return (
    <div className="publicShare">
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 5 }} />
      ) : (
        <>
          <HeaderCommon />
          {notFound ? (
            <NoLonger />
          ) : (
            <div className="shortContainer">
              {" "}
              <div className="commonCardBg shareCard">
                <button className="reportMediaBtn" onClick={handleClick}>
                  Report Media
                </button>
                <div className="showDetails">
                  <div className="showDetails_inner">
                    <p>
                      Name of the file: <span>{mediaDetails?.name ?? "-"}</span>
                    </p>
                    <p>
                      File Type:{" "}
                      <span>{extractFileName(mediaDetails?.format) ?? ""}</span>
                    </p>
                  </div>
                  <div className="showDetails_inner">
                    <p>
                      Uploaded At:{" "}
                      <span>
                        {mediaDetails?.created_at
                          ? convertDate(mediaDetails?.created_at)
                          : "-"}
                      </span>
                    </p>
                    <p>
                      File Size:{" "}
                      <span>
                        {mediaDetails?.size
                          ? formatStorageSize(mediaDetails?.size)
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="publicShare_imageSec">
                  {ismodalOpen && (
                    <MediaDisplay
                      selectedMedia={mediaDetails}
                      setModalOpen={setIsModalOpen}
                      isPublicDisplay={true}
                    />
                  )}
                  {/* <img src={`${env.ipfsUrl}/${ipfsId}`} alt="" /> */}
                </div>
                <ButtonCustom
                  // className={isDisabled ? "disableBtn" : ""}
                  disabled={isDisabled}
                  onClick={handleDownload}
                  className={`${isDisabled ? "disableBtn" : ""} shareCard_button`}
                  regularBtn
                  label="Download"
                  icon={<DownloadIcon />}
                />
              </div>
            </div>
          )}
        </>
      )}
      <ReportMediaModal
        show={show}
        closeModal={closeModal}
        handleClick={handleClick}
        mediaId={mediaId}
        setShow={setShow}
        contactDetail={contactDetail}
        setContactDetail={setContactDetail}
      />
    </div>
  );
}

export default PublicShare;
