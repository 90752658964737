import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useLogout from "../../CustomHooks/useLogout";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { setWalletAddress } from "../../store/slices/authSlice";

const useWalletListner = () => {
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const { address } = useWeb3ModalAccount();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!address) {
        handleLogOut();
      } else {
        dispatch(setWalletAddress(address));
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [address, dispatch]);

  return;
};

export default useWalletListner;
