import introJs from "intro.js";
import "intro.js/introjs.css";

export const startUserGuide = (data) => {
  let steps = [];
  if (data === "fileManagement") {
    steps = [
      {
        element: "#create-file-button",
        intro:
          "Create a new container workspace to start uploading and organizing your media. You can create multiple containers to suit your needs.",
        position: "bottom-center",
      },
    ];
  } else if (data === "addMember") {
    steps = [
      {
        element: "#create-member-button",
        intro:
          "Click here to invite members to your container workspace. The number of members you can add depends on your subscription plan",
        position: "bottom-center",
      },
    ];
  }

  const intro = introJs();
  intro.setOptions({
    steps,
    showStepNumbers: true,
    exitOnOverlayClick: false,
    showBullets: true,
    disableInteraction: true,
  });

  intro.onchange(() => {
    const currentStep = intro._currentStep;
    if (currentStep === 0) {
      localStorage.setItem(`${data}Guide`, "false");
    }
  });

  intro.onexit(() => {
    localStorage.setItem(`${data}Guide`, "false");
  });

  intro.start();
};
