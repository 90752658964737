import {
  BillingIcon,
  SettingsIcon,
  SignOutIcon,
  DropdownIcon,
  BellIcon,
  CopyIcon,
  CrossIcon2,
  // ReportSvg,

  // UserIcon,
  // PlateIcon,
  // Upload,
  // DeletedFile,
} from "../../../Assets/Assets";
import "./Header.scss";
import React, { useContext, useEffect, useState } from "react";
import { Drawer, Dropdown, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import user from "../../../Assets/images/user.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import httpRequest from "../../../Utils/httpsRequest";
import { convertDate, copy, formatString } from "../../../Utils/helpers";
import useLogout from "../../../CustomHooks/useLogout";
import HammerMenu from "../SidebarDashboard/HammerMenu";
import { Path } from "../../../Routing/Constant/RoutePaths";
import useCurrentWidth from "../../customHooks/useCurrentWidth";
import SidebarDashboard from "../SidebarDashboard/SidebarDashboard";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../../constants";
import toast from "react-hot-toast";

import { AppContext } from "../../../store/Context";
import {
  fetchNotifications,
  updateNotificationStatus,
} from "../../../store/slices/notificationSlice";
import { ExportOutlined } from "@ant-design/icons";
import {
  addToBreadcrumb,
  resetBreadcrumb,
} from "../../../store/slices/breadcrumbSlice";
import NoData from "../../noData/NoData";
import { env } from "../../../constants/env";

function Header() {
  const width = useCurrentWidth();
  const handleLogout = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname;
  const { clearUploadFileQueue } = useContext(AppContext);
  const [collapsed, setCollapsed] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userId } = useSelector((state) => state.user);
  const { csrfToken } = useSelector((state) => state.auth);
  const { walletAddress } = useSelector((state) => state.auth);
  const { updateNotification } = useSelector((state) => state.notifications);
  const { planType } = useSelector((state) => state.user);

  if (!planType) {
    navigate(Path.ACCOUNT_TYPE);
  }

  useEffect(() => {
    dispatch(fetchNotifications({ userId, csrfToken, handleLogout }));
  }, []);

  const unreadNotifications =
    updateNotification &&
    updateNotification.filter(
      (notification) => notification.status === "unread",
    );

  const handleAudioPlayback = () => {
    const audio = new Audio("/audio/notification.wav");
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  useEffect(() => {
    if (width <= 767) {
      setIsModalOpen(false);
    }
  }, [width]);

  try {
    useEffect(() => {
      const socketConnection = new WebSocket(
        `${env.websocketBackendUrl}${API_URL.WS_NOTIFICATION}`,
      );
      socketConnection.withCredentials = true;
      socketConnection.addEventListener("message", (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data?.notification) {
            toast(data?.notification?.message, { icon: "👏" });

            if (document.visibilityState === "visible") {
              handleAudioPlayback();
            }
            dispatch(fetchNotifications({ userId, csrfToken, handleLogout }));
          } else if (data.success) {
            console.log("Success Message:", data);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      });

      socketConnection.addEventListener("open", () => {
        console.log("WebSocket connection established");
      });

      socketConnection.addEventListener("error", (error) => {
        console.error("WebSocket connection error:", error);
      });

      socketConnection.addEventListener("close", () => {
        console.log("WebSocket connection closed");
      });

      return () => {
        socketConnection.close();
      };
    }, []);
  } catch (error) {
    console.error("Error initializing WebSocket connection:", error);
  }

  const notificationStatusUpdate = async (notificationId) => {
    try {
      await dispatch(
        updateNotificationStatus({ notificationId, csrfToken, handleLogout }),
      );
    } catch (error) {
      console.log("Error while deleting notification: ", error);
    }
  };

  const showModal = () => {
    if (width > 767) {
      setIsModalOpen(true);
    } else {
      navigate(Path.NOTIFICATION);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSignOut = async () => {
    try {
      const response = await httpRequest(
        API_URL.LOGOUT,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogout,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        handleLogout();
        clearUploadFileQueue();
      } else {
        console.log(response?.response?.data?.message);
      }
    } catch (error) {
      console.log("Error while signing out: ", error);
    }
  };

  const items = [
    {
      label: (
        <Link to={Path.BILLING_INFORMATION}>
          <BillingIcon /> <span>Billing</span>
        </Link>
      ),
      key: "0",
      disabled: true,
    },
    {
      label: (
        <Link to={Path.SETTINGS}>
          <SettingsIcon size={16} /> <span>Settings</span>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <div className="signOut" onClick={handleSignOut}>
          <SignOutIcon />
          <span>Sign Out</span>
        </div>
      ),
      key: "2",
    },
    {
      /*
    {
      label: (
        <Link to={Path.CONTACTUS}>
          <ReportSvg /> <span>Report Media</span>
        </Link>
      ),
      key: "2",
    }
    */
    },
  ];
  const handleNavigation = (item) => {
    notificationStatusUpdate(item?.id);
    if (item?.shareable_type === "media") {
      console.log("  media...");
    } else {
      dispatch(resetBreadcrumb());
      dispatch(
        addToBreadcrumb({
          id: `folder-management/container/${item?.shareable_id}`,
          name: item?.shareable_name,
          container_access_level: item?.access_level,
        }),
      );
      setIsModalOpen(false);
      navigate(`folder-management/container/${item?.shareable_id}`);
    }
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <div className="header">
      <div className="header_left">
        {" "}
        {width < 992 ? (
          <>
            <HammerMenu onClick={showDrawer} onClose={onClose} open />
            <Drawer
              placement="left"
              closable={true}
              onClose={onClose}
              open={drawerVisible}
            >
              <SidebarDashboard
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                setDrawerState={onClose}
              />
            </Drawer>
          </>
        ) : null}
        <h3>
          {currentLocation === Path.DASHBOARD && "Dashboard"}
          {currentLocation === Path.FILE_MANAGEMENT && "File Management"}
          {currentLocation === Path.ACCESSKEY && "Access Key"}
          {currentLocation === Path.MEMBERS && "Members"}
          {currentLocation === Path.DAO && "DAO"}
          {currentLocation === Path.SETTINGS && "Account Settings"}
          {currentLocation === Path.BILLING_INFORMATION &&
            "Billing Information"}
          {currentLocation === Path.NOTIFICATION && "Notifications"}
          {currentLocation === Path.CONTACTUS && "Report Media"}
        </h3>
      </div>
      <div className="header_right">
        <div className="header_right_bellicon">
          <div className="bell-container" onClick={showModal}>
            <BellIcon />
            {unreadNotifications && unreadNotifications.length > 0 ? (
              <div className="red-dot"></div>
            ) : (
              <></>
            )}
          </div>

          {walletAddress && (
            <div className="header_right_hash">
              <span
                onClick={() => {
                  copy(walletAddress);
                  toast.success("Copied!");
                }}
              >
                <CopyIcon />
              </span>
              <p>{formatString(walletAddress)}</p>
            </div>
          )}
        </div>

        <Dropdown
          className="menuDropdown"
          overlayClassName="userDropdown"
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <img src={user} alt="User" />
            <DropdownIcon />
          </a>
        </Dropdown>
      </div>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
        className="common-modal notificationModal notification"
      >
        <div className="modal-header notificationModal_head">
          <div className="notificationModal_head_left">
            <div className="icon">
              <BellIcon />
            </div>
            <div className="title">Notifications</div>
          </div>
          <div className="notificationModal_head_right">
            <Link to={Path.NOTIFICATION}>
              <button onClick={handleCancel}>View All</button>
            </Link>
          </div>
        </div>
        <div className="modal-content notificationModal_content">
          {updateNotification && updateNotification.length > 0 ? (
            updateNotification
              .filter((item) => item?.status === "unread")
              .slice(0, 5).length > 0 ? (
              updateNotification
                .filter((item) => item?.status === "unread")
                .slice(0, 5)
                .map((item, index) => (
                  <div key={index} className="notification-container">
                    <div
                      className="notificationModal_content_inner"
                      onClick={() => handleNavigation(item)}
                    >
                      <BellIcon />
                      <div>
                        {item?.content?.length > 45 ? (
                          <h4>{formatString(item?.content, 45, 0)}</h4>
                        ) : (
                          <h4>{item?.content}</h4>
                        )}
                        <p>{convertDate(item?.created_at)}</p>
                      </div>
                    </div>
                    <div className="profileOption">
                      {(item?.shareable_type === "container" ||
                        item?.shareable_type === "folder") && (
                        <div onClick={() => handleNavigation(item)}>
                          <ExportOutlined />
                        </div>
                      )}
                      <div onClick={() => notificationStatusUpdate(item?.id)}>
                        <CrossIcon2 />
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <NoData
                className="no-notifications"
                message="No notifications found"
              />
            )
          ) : (
            <NoData
              className="no-notifications"
              message="No notifications found"
            />
          )}
        </div>
      </Modal>
      {/* {isMediaModalOpen && (
        <MediaDisplay
          selectedMedia={selectedFile}
          setModalOpen={setMediaModalOpen}
        />
      )} */}
    </div>
  );
}

export default Header;
