import React from "react";
import { PAGES } from "./Pages.jsx";
import ScrollToTop from "../Common/ScrollToTop/ScrollToTop.jsx";
import PrivateRoute from "./Private/Private.jsx";
import PublicRoute from "./Public/Public.jsx";

export const publicRoutes = [
  {
    path: "/",
    element: (
      <ScrollToTop>
        <PublicRoute />
      </ScrollToTop>
    ),
    children: PAGES.PUBLIC_PAGES,
  },
];

export const privateRoutes = [
  {
    path: "/",
    element: (
      <ScrollToTop>
        <PrivateRoute />
      </ScrollToTop>
    ),
    children: PAGES.PRIVATE_PAGES,
  },
];
