import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../../Utils/httpsRequest";
import {
  API_URL,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
  INFINITE_SCROLLBAR,
} from "../../constants";
import toast from "react-hot-toast";
// import { useSelector } from "react-redux";

export let isNewCreated = false;
export const setIsNewCreated = (value) => {
  isNewCreated = value;
};

export let showContainerModal = false;
export const handleDashboardRedirect = (value) => {
  showContainerModal = value;
};

export const fetchContainers = createAsyncThunk(
  "containers/fetchContainers",
  async (
    {
      handleLogOut,
      searchTerm = "",
      page = INFINITE_SCROLLBAR.DEFAULT_PAGE,
      limit = INFINITE_SCROLLBAR.DEFAULT_LIMIT,
      prevData = null,
      csrfToken,
    },
    { rejectWithValue },
  ) => {
    try {
      let isMoreData = false;
      const response = await httpRequest(
        `${API_URL.GET_ALL_CONTAINERS}?page=${page}&limit=${limit}&search=${encodeURIComponent(searchTerm)}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
        null,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        let containerData = response?.data?.container;
        let data;
        if (page == 1) {
          data = containerData;
        } else {
          prevData?.length > 0
            ? (data = [...prevData, ...containerData])
            : (data = containerData);
        }

        if (containerData?.length == limit) {
          isMoreData = true;
        }
        return { isMoreData, data };
      } else {
        return rejectWithValue(
          response?.error?.message || "Someting went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Someting went wrong");
    }
  },
);

export const createContainer = createAsyncThunk(
  "containers/createContainer",
  async (
    { containerName, handleLogOut, csrfToken },
    { rejectWithValue, getState },
  ) => {
    if (!containerName) return rejectWithValue("Container name is empty");
    const existingContainers = getState()?.containers?.containers;
    const isDuplicate = existingContainers.some(
      (container) =>
        container.name.toLowerCase() === containerName.toLowerCase(),
    );

    if (isDuplicate) {
      toast.error("A container with this name already exists");
      return rejectWithValue("A container with this name already exists");
    }
    try {
      const response = await httpRequest(
        API_URL.CREATE_CONTAINER,
        HTTP_METHOD.POST,
        { container_name: containerName },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        setIsNewCreated(true);
        return response;
      } else {
        toast.error(response?.error?.message || "Something went wrong");
        return rejectWithValue(
          response?.error?.message || "Failed to create container",
        );
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
      return rejectWithValue(error?.message || "Something went wrong");
    }
  },
);

export const updateContainer = createAsyncThunk(
  "containers/updateContainer",
  async (
    { selectedId, newName, handleLogOut, csrfToken },
    { rejectWithValue, getState },
  ) => {
    if (!newName) {
      return rejectWithValue("Container name is empty");
    }
    const existingContainers = getState()?.containers?.containers;
    const isDuplicate = existingContainers.some(
      (container) => container.name.toLowerCase() === newName.toLowerCase(),
    );

    if (isDuplicate) {
      return rejectWithValue("A container with this name already exists");
    }

    try {
      const response = await httpRequest(
        `${API_URL.UPDATE_CONTAINER}/${selectedId}`,
        HTTP_METHOD.PUT,
        { container_name: newName },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        toast.error(response?.error?.message || "Something went wrong");
        return rejectWithValue(
          response?.error?.message || "Failed to update container",
        );
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");

      return rejectWithValue(error.message || "Failed to update container");
    }
  },
);

const containerSlice = createSlice({
  name: "containers",
  initialState: {
    containers: [],
    status: "idle",
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearContainerState: (state) => {
      console.log("Clearing container state");

      state.initialState = {
        containers: [],
        status: "idle",
        error: null,
      };
      console.log("done with container state");
    },
  },
  extraReducers: (builder) => {
    builder

      // Fetch containers
      .addCase(fetchContainers.pending, (state, action) => {
        if (action?.meta?.arg?.page == 1) {
          state.status = "loading";
        }
      })
      .addCase(fetchContainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.containers = action.payload.data;
        state.isMoreData = action.payload.isMoreData;
        state.showContainerModal = action.payload.showContainerModal;
        state.loading = false;
      })
      .addCase(fetchContainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Create container
      .addCase(createContainer.pending, (state) => {
        state.status = "creating";
      })
      .addCase(createContainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.containers = [action?.payload?.data, ...state.containers];
        state.payload = action;
        state.loading = false;
      })
      .addCase(createContainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update container
      .addCase(updateContainer.pending, (state) => {
        state.status = "updating";
      })
      .addCase(updateContainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedContainer = state.containers.map((container) =>
          container.id === action?.payload?.data.id
            ? action?.payload?.data
            : container,
        );
        state.containers = updatedContainer;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateContainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearError, clearContainerState } = containerSlice.actions;
export default containerSlice.reducer;
