import React from "react";
import "./AccessKey.scss";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { DeleteIcon, KeySquareIcon, PlusIcon } from "../../Assets/Assets";

function AccessKey() {
  const accessKeys = [
    {
      name: "Greek.png",
      accessName: "Access Name",
      createdDate: "July 03, 2024 | 07:24 PM",
    },
    {
      name: "Greek.png",
      accessName: "Access Name",
      createdDate: "July 03, 2024 | 07:24 PM",
    },
    {
      name: "Greek.png",
      accessName: "Access Name",
      createdDate: "July 03, 2024 | 07:24 PM",
    },
    {
      name: "Greek.png",
      accessName: "Access Name",
      createdDate: "July 03, 2024 | 07:24 PM",
    },
    {
      name: "Greek.png",
      accessName: "Access Name",
      createdDate: "July 03, 2024 | 07:24 PM",
    },
    {
      name: "Greek.png",
      accessName: "Access Name",
      createdDate: "July 03, 2024 | 07:24 PM",
    },
  ];
  return (
    <div className="accessKey">
      <div className="accessKey_head">
        <InputCustom
          customClass="commonSearchInput"
          label
          searchInputs
          placeholder="Search Container"
        />
        <ButtonCustom smallBtn label="New Access Key">
          <PlusIcon />
        </ButtonCustom>
      </div>
      <div className="accessKey_bottom">
        {accessKeys.map((key, index) => (
          <div className="accessKey_card commonCardBg" key={index}>
            <div className="accessKey_card_top">
              <div>
                <div>
                  <KeySquareIcon /> <h4>{key.name}</h4>
                </div>
                <DeleteIcon />
              </div>
              <p>{key.accessName}</p>
            </div>
            <div className="accessKey_card_bottom">
              <p>Created date</p>
              <h5>{key.createdDate}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AccessKey;
