import "./DashboardLayout.scss";
import Loader from "../../Loader/Loader";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../../store/Context";
import { Outlet, useLocation } from "react-router-dom";
import { Path } from "../../../Routing/Constant/RoutePaths";
import useCurrentWidth from "../../customHooks/useCurrentWidth";
import AccountType from "../../../Pages/AccountType/AccountType";
import PublicShare from "../../../Pages/PublicShare/PublicShare";
// import UploadPopup from "../../Components/UploadPopup/UploadPopup";
import React, { useState, useEffect, useRef, useContext } from "react";
import SidebarDashboard from "../../Components/SidebarDashboard/SidebarDashboard";
import Footer from "../../Components/Footer/Footer";
import UploadPopupNew from "../../Components/UploadPopup/UploadPopupNew";

function DashboardLayout() {
  const location = useLocation();
  const width = useCurrentWidth();
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const { uploadFileQueue,  uploadFailed,uploadSucessfully,} = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [location]);

  const isAccountTypePage = location.pathname === Path.ACCOUNT_TYPE;
  const isPublicSharePage = location.pathname == "/file";

  return (
    <>
      {loading ? (
        <Loader />
      ) : isAccountTypePage ? (
        <AccountType />
      ) : isPublicSharePage ? (
        <PublicShare />
      ) : (
        <div className="dashLayout">
          <div className="dashLayout_inner">
            {width >= 992 && (
              <SidebarDashboard
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            )}
            <div className="dashLayout_content">
              <div className="dashLayout_content_header">
                <Header />
              </div>
              <div
                className="dashLayout_content_body"
                ref={contentRef}
                id="scroll-target"
              >
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />

          {uploadFileQueue?.length ||uploadFailed?.length|| uploadSucessfully?.length ? <UploadPopupNew /> : null}
        </div>
      )}
    </>
  );
}

export default DashboardLayout;
