import {
  API_URL,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
  INFINITE_SCROLLBAR,
} from "../../constants";
import httpRequest from "../../Utils/httpsRequest";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resetBreadcrumb } from "./breadcrumbSlice";
import toast from "react-hot-toast";

export const fetchContainerFolderMedia = createAsyncThunk(
  "folders/fetchContainerFolderMedia",
  async (
    {
      id,
      handleLogOut,
      page = INFINITE_SCROLLBAR.DEFAULT_PAGE,
      limit = INFINITE_SCROLLBAR.DEFAULT_LIMIT,
      searchTerm = "",
      prevData = null,
      csrfToken,
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      let isMoreData = true;
      const response = await httpRequest(
        `${API_URL.GET_ALL_FOLDER}${id}?page=${page}&limit=${limit}&search=${encodeURIComponent(searchTerm)}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        const mergedFolders = (response?.data?.folders || []).concat(
          response?.data?.media || [],
        );

        let data;
        if (page == 1) {
          data = mergedFolders;
        } else {
          prevData?.length > 0
            ? (data = [...prevData, ...mergedFolders])
            : (data = mergedFolders);
        }

        if (mergedFolders?.length < limit) {
          isMoreData = false;
        }

        return { isMoreData, data };
      } else {
        if (response?.code === HTTP_STATUS_CODE.UNAUTHORIZED) {
          dispatch(resetBreadcrumb());
          toast.error(response?.error?.message || "Something went wrong");
        }

        return rejectWithValue(
          response?.error?.message || "Failed to fetch folders",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch folders");
    }
  },
);

// Fetch nested folders
export const fetchNestedFolders = createAsyncThunk(
  "folders/fetchNestedFolders",
  async (
    {
      id,
      handleLogOut,
      page = 1,
      limit = 40,
      searchTerm = "",
      prevData = null,
      csrfToken,
      parentsID,
    },
    { rejectWithValue },
  ) => {
    try {
      // console.log("parentsID", parentsID);
      let isMoreData = true;
      const response = await httpRequest(
        `${API_URL.GET_NESTED_FOLDER}${id}?container_id=${parentsID}&page=${page}&limit=${limit}&search=${encodeURIComponent(searchTerm)}`,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        const mergedFolders = (response?.data?.folders || []).concat(
          response?.data?.media || [],
        );
        let data;
        if (page == 1) {
          data = mergedFolders;
        } else {
          prevData?.length > 0
            ? (data = [...prevData, ...mergedFolders])
            : (data = mergedFolders);
        }

        if (mergedFolders?.length < limit) {
          isMoreData = false;
        }
        return { isMoreData, data };
      } else {
        console.log("Error", response);

        return rejectWithValue(
          response?.error?.message || "Failed to fetch folders",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch folders");
    }
  },
);

// Create a new folder
export const createFolder = createAsyncThunk(
  "folders/createFolder",
  async (
    { folderName, handleLogOut, type, id = "", parentID = "", csrfToken },
    { getState, rejectWithValue },
  ) => {
    if (!folderName) return rejectWithValue("Folder name is empty");

    const existingFolder = getState()?.folders?.folders;
    const isDuplicate = existingFolder.some(
      (folder) => folder.name.toLowerCase() === folderName.toLowerCase(),
    );

    if (isDuplicate) {
      toast.error("A Folder with this name already exists");

      return rejectWithValue("A Folder with this name already exists");
    }

    try {
      let response;

      if (type === "container") {
        response = await httpRequest(
          API_URL.CREATE_FOLDER,
          HTTP_METHOD.POST,
          { folder_name: folderName, container_id: id },
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );
      } else {
        response = await httpRequest(
          API_URL.CREATE_FOLDER,
          HTTP_METHOD.POST,
          { folder_name: folderName, container_id: parentID, parent_id: id },
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );
      }

      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        toast.error(response?.error?.message || "Failed to create folder");
        return rejectWithValue(
          response?.error?.message || "Failed to create folder",
        );
      }
    } catch (error) {
      toast.error(error?.message || "Failed to create folder");
      return rejectWithValue(error?.message || "Something went wrong");
    }
  },
);

// Update an existing folder
export const updateFolder = createAsyncThunk(
  "folders/updateFolder",
  async (
    { folderId, newFolderName, handleLogOut, csrfToken },
    { rejectWithValue },
  ) => {
    if (!newFolderName) return rejectWithValue("Folder name is empty");

    try {
      const response = await httpRequest(
        `${API_URL.UPDATE_FOLDER}/${folderId}`,
        HTTP_METHOD.PUT,
        { folder_name: newFolderName },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        toast.error(response?.error?.message || "Failed to create folder");
        return rejectWithValue(
          response?.error?.message || "Failed to update folder",
        );
      }
    } catch (error) {
      toast.error(error?.message || "Failed to create folder");
      return rejectWithValue(error.message || "Failed to update folder");
    }
  },
);

// Upload files
export const uploadFiles = createAsyncThunk(
  "folders/uploadFiles",
  async (
    { formDataBody, handleLogOut, csrfToken, signal },
    { rejectWithValue },
  ) => {
    try {
      const response = await httpRequest(
        API_URL.UPLOAD_FILES,
        HTTP_METHOD.POST,
        formDataBody,
        {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": csrfToken,
          Accept: "*/*",
        },
        null,
        handleLogOut,
        signal,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response.data;
      } else {
        toast.error(response?.error?.message || "Failed to upload files");
        throw new Error(response?.error?.message ?? "Failed to upload files");
      }
    } catch (error) {
      return rejectWithValue({
        error: true,
        msg: error.message ?? "Failed to upload files",
      });
    }
  },
);

// Upload folder
export const uploadFolder = createAsyncThunk(
  "folders/uploadFolder",
  async ({ formDataBody, handleLogOut, signal }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        API_URL.UPLOAD_FOLDER,
        HTTP_METHOD.POST,
        formDataBody,
        null,
        null,
        handleLogOut,
        signal,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        return {
          error: false,
          response,
        };
      } else {
        toast.error(response?.error?.message || "Failed to upload folder");
        throw new Error(response?.error?.message || "Failed to upload folder");
      }
    } catch (error) {
      return rejectWithValue({
        error: true,
        msg: error.message ?? "Failed to upload folder",
      });
    }
  },
);

export const deleteFiles = createAsyncThunk(
  "folders/deleteFile",
  async ({ ids, csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${API_URL.DELETE_MEDIA}${ids}`,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        toast.error(response?.error?.message || "Something went wrong");
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  },
);

export const folderDelete = createAsyncThunk(
  "folders/folderDelete",
  async ({ ids, csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${API_URL.DELETE_FOLDER}${ids}`,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        return response;
      } else {
        toast.error(response?.error?.message || "Failed to delete folder");
        return rejectWithValue(
          response?.error?.message || "Something went wrong",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  },
);

// Folder slice
const folderSlice = createSlice({
  name: "folders",
  initialState: {
    folders: [],
    status: "idle",
    error: null,
    dataLoding: false,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch folders and media
      .addCase(fetchContainerFolderMedia.pending, (state, action) => {
        if (action?.meta?.arg?.page == 1) {
          state.status = "loading";
          state.dataLoding = true;
        }
      })
      .addCase(fetchContainerFolderMedia.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.folders = action.payload.data;
        state.dataLoding = false;
        state.isMoreData = action.payload.isMoreData;
      })
      .addCase(fetchContainerFolderMedia.rejected, (state, action) => {
        state.folders = [];
        state.status = "failed";
        state.dataLoding = false;
        state.error = action.payload;
      })

      // Fetch nested folders
      .addCase(fetchNestedFolders.pending, (state) => {
        state.dataLoding = "loading";
        state.dataLoding = true;
      })
      .addCase(fetchNestedFolders.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.error = null;
        state.payload = action.payload;
        state.folders = action.payload.data;
        state.isMoreData = action.payload.isMoreData;
        state.dataLoding = false;
      })
      .addCase(fetchNestedFolders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.dataLoding = false;
      })

      // Create folder
      .addCase(createFolder.pending, (state) => {
        state.status = "creating";
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.folders = [action?.payload?.data, ...state.folders];
        state.loading = false;
        state.error = null;
      })
      .addCase(createFolder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update folder
      .addCase(updateFolder.pending, (state) => {
        state.status = "updaing";
      })
      .addCase(updateFolder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.loading = false;
        state.folders = state.folders.map((folder) => {
          const updatedFolder =
            folder.id === action?.payload?.data?.id
              ? action?.payload?.data
              : folder;
          return updatedFolder;
        });
      })
      .addCase(updateFolder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      })

      // Upload files
      .addCase(uploadFiles.pending, (state) => {
        state.status = "uploadig";
      })
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.folders = [action?.payload, ...state.folders];
        state.loading = false;
        state.error = null;
      })
      .addCase(uploadFiles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Upload Folder
      .addCase(uploadFolder.pending, (state) => {
        state.status = "uploadig";
      })
      .addCase(uploadFolder.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action?.payload?.response?.data) {
          state.folders = [action?.payload?.response?.data, ...state.folders];
        }
        state.payload = action;
        state.loading = false;
        state.error = null;
      })
      .addCase(uploadFolder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //DeleteFile
      .addCase(deleteFiles.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(deleteFiles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.folders = state.folders.filter(
          (folder) => folder.id !== action.meta.arg.ids,
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteFiles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //Delete Folder
      .addCase(folderDelete.pending, (state) => {
        state.status = "deleting";
      })
      .addCase(folderDelete.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.folders = state.folders.filter(
          (folder) => folder.id !== action.meta.arg.ids,
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(folderDelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { clearError } = folderSlice.actions;
export default folderSlice.reducer;
