import { useEffect } from "react";
import { ERR_MSG, REGEX } from "../constants";
import disposableDomains from "disposable-email-domains";

// export const validateMail = (email) => {
//   const MIN_EMAIL_LENGTH = 6;
//   const MAX_EMAIL_LENGTH = 254;
//   const MIN_LOCAL_PART_LENGTH = 3;
//   const VALID_TLDS = [
//     "com",
//     "org",
//     "net",
//     "edu",
//     "gov",
//     "io",
//     "co",
//     "uk",
//     "in",
//   ];

//   const LOCAL_PART_REGEX =
//     /^[a-zA-Z0-9](?!.*[._-]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9]$/;
//   const trimmedEmail = email?.trim();

//   if (!trimmedEmail || trimmedEmail.length === 0) {
//     return { isValidMail: false, error: ERR_MSG.REQUIRED_INPUT };
//   }

//   // 2. Check minimum length (total length of email)
//   if (trimmedEmail.length < MIN_EMAIL_LENGTH) {
//     return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
//   }

//   if (
//     trimmedEmail.length < MIN_EMAIL_LENGTH ||
//     trimmedEmail.length > MAX_EMAIL_LENGTH
//   ) {
//     return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
//   }

//   const atSymbolCount = (trimmedEmail.match(/@/g) || []).length;
//   if (atSymbolCount !== 1) {
//     return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
//   }

//   const [localPart, domain] = trimmedEmail.split("@");
//   if (
//     localPart.length < MIN_LOCAL_PART_LENGTH ||
//     !LOCAL_PART_REGEX.test(localPart)
//   ) {
//     return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
//   }
//   if (localPart.includes("+")) {
//     console.log("localPart", localPart);

//     return { isValidMail: false, error: ERR_MSG.PLUS_ADDRESSING_NOT_ALLOWED };
//   }

//   if (!domain) {
//     return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
//   }

//   const domainParts = domain.split(".");

//   if (domainParts[0].length < 2 || !VALID_TLDS.includes(domainParts.pop())) {
//     return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
//   }

//   return { isValidMail: true, error: "" };
// };

export const validateMail = (email) => {
  try {
    if (REGEX.PLUS_IN_LOCALPART.test(email)) {
      return { isValidMail: false, error: ERR_MSG.PLUS_ADDRESSING_NOT_ALLOWED };
    }

    if (!REGEX.EMAIL.test(email)) {
      return { isValidMail: false, error: ERR_MSG.INVALID_EMAIL };
    }
    const domain = email.split("@")[1];
    const isFake = disposableDomains.includes(domain);
    if (isFake) {
      return { isValidMail: false, error: ERR_MSG.NOT_SUPPORTED_MAIL };
    }

    return { isValidMail: true, error: "" };
  } catch (error) {
    console.log("Error while updating: ", error);
    return { isValidMail: false, error: ERR_MSG.NOT_SUPPORTED_MAIL };
  }
};

export const validateName = (value) => {
  if (/^\s+|\s+$/.test(value) || / {2,}/.test(value)) {
    return ERR_MSG.CONSECUTIVE_SPACE; // Invalid if it starts/ends with or has consecutive spaces
  }
  const name = value.trim();
  if (name.length === 0) {
    return ERR_MSG.REQUIRED_INPUT;
  } else if (name.length < 3 || name.trim().length >= 19) {
    return ERR_MSG.INVALID_LENGTH;
  } else if (!REGEX.CONTAINER_NAME.test(name)) {
    return ERR_MSG.ALPHANUMERIC_CHARACTERS;
  } else if (/ {2,}/.test(name)) {
    return ERR_MSG.CONSECUTIVE_SPACE;
  } else {
    return "";
  }
};

export const folderAndContainerNameValidation = (name) => {
  const regex = /^[a-zA-Z0-9-_ ]+$/;

  if (regex.test(name)) {
    return {
      isValid: true,
      message: "",
    };
  } else {
    return {
      isValid: false,
      message:
        "Invalid name. Only letters, digits, spaces, hyphens, and underscores are allowed.",
    };
  }
};

const useKeyPress = (key, action, deps = []) => {
  useEffect(() => {
    if (action) {
      const handleKeyPress = (event) => {
        if (event.key === key) {
          action();
        }
      };

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [key, action, ...deps]);
};

export default useKeyPress;
