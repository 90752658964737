import { Empty, Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
// import { TheadIcon, ThreeDots } from "../../Assets/Assets";

import NoData from "../../Assets/images/notfound.svg";
import httpRequest from "../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";
import useLogout from "../../CustomHooks/useLogout";
import { useSelector } from "react-redux";
import { convertDate } from "../../Utils/helpers";

function BillingTable() {
  const [datas, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);
  const handleLogOut = useLogout();

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        setLoading(true);
        const res = await httpRequest(
          API_URL.BILLING_LIST,
          null,
          HTTP_METHOD.GET,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );

        if (res.code === HTTP_STATUS_CODE.OK) {
          setData(res.data);
        } else {
          console.error("Failed to fetch billing data:", res.error);
        }
      } catch (error) {
        console.error("Failed to fetch billing data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData();
  }, []);

  const columns = [
    {
      title: "Payment Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: ["Amount"],
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: ["Status"],
      dataIndex: "status",
      key: "status",
    },
  ];

  const formattedData =
    datas &&
    datas.map((item, index) => ({
      key: index,
      dateCreated: convertDate(item.payment_date) || "-",
      plan: item?.plan_name,
      amount: item.amount || "-",

      status:
        item.status === "success" ? (
          item.status || "-"
        ) : (
          <span className="rejected">Rejected</span>
        ),
    }));

  return (
    <div>
      {loading ? (
        <div className="no-events-found" data-testid="skeleton-container">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      ) : (
        <Table
          columns={columns}
          scroll={{ y: 550, x: "max-content" }}
          dataSource={formattedData && formattedData}
          className="commontable"
          locale={{
            emptyText: (
              <Empty
                image={<img src={NoData} alt="No data found" />}
                description="No Data Found"
              />
            ),
          }}
          pagination={false}
        />
      )}
    </div>
  );
}

export default BillingTable;
