import React from "react";
import "./AccountCard.scss";
import PropTypes from "prop-types";
import { TickRound } from "../../../Assets/Assets";
import ButtonCustom from "../../Components/ButtonCustom/ButtonCustom";
import { Skeleton } from "antd";
import ComingSoon from "../../ComingSoon/ComingSoon";

function AccountCard(props) {
  const {
    title,
    price,
    duration,
    description,
    features,
    valueIconSrc,
    buttonText,
    TokenValue,
    onClick,
    icon,
    value,
    loading,
    btnDisabled,
    className,
    btnActive,
  } = props;
  return (
    <div
      className={`accountCard ${className || ""}  ${loading ? "" : "hover"}`}
    >
      {loading ? (
        <>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 2, width: "70%" }}
          />
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 5, width: "50%" }}
          />
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 3, width: "50%" }}
          />
          <Skeleton active title={false} paragraph={{ rows: 2 }} />
          <Skeleton.Button active size="large" block />
        </>
      ) : (
        <div className="accountCard_inner">
          <div>
            {" "}
            <div className="accountCard_tittle">
              {icon && icon}
              <h4>
                {title === "free"
                  ? `${title} Trail`
                  : title === "pro"
                    ? `${title} Account`
                    : title}
              </h4>
            </div>
            <h2>
              {price && price}
              <span>/ {duration && duration}</span>
              {TokenValue && (
                <p>
                  = <img src={valueIconSrc} alt="" /> ({value && value})
                </p>
              )}
            </h2>
            <h3>{description && description}</h3>
            <div className="accountCard_border"></div>
            <div className="accountCard_includes">
              <h5>Includes:</h5>
              <ul>
                {features.map((feature, index) => (
                  <li key={index}>
                    <TickRound /> <p>{feature}</p>
                  </li>
                ))}
                {title === "free" ? (
                  <li>
                    <TickRound /> <p> No data encryption available</p>
                  </li>
                ) : (
                  <li>
                    <TickRound /> <p>Data encryption available</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="accountCard_btn">
            {!btnDisabled ? (
              <>
                <ButtonCustom
                  // name={title}
                  className={`  ${btnDisabled ? "disableBtn" : !btnActive ? "cursor-notAllowed" : ""} `}
                  onClick={() => onClick(title)}
                  regularBtn
                  label={buttonText && buttonText}
                />
              </>
            ) : (
              <>
                <ComingSoon />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

AccountCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  btnDisabled: PropTypes.bool,
  icon: PropTypes.bool,
  value: PropTypes.string,
  valueIconSrc: PropTypes.string,
  TokenValue: PropTypes.string,
  btnActive: PropTypes.bool,
  className: PropTypes.string,
};

AccountCard.defaultProps = {
  loading: false,
};

export default AccountCard;
