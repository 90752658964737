import React from "react";
import useCurrentWidth from "../../customHooks/useCurrentWidth";
import PropTypes from "prop-types";

function HammerMenu({ open, closed, onClick }) {
  const width = useCurrentWidth();

  return (
    <div>
      {width < 992 ? (
        <button
          className={`btnToggle ${open ? "open" : ""} ${closed ? "closed" : ""}`}
          onClick={onClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      ) : null}
    </div>
  );
}

HammerMenu.propTypes = {
  closed: PropTypes.bool,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HammerMenu;
