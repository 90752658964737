import "./AccountType.scss";
// import { Switch } from "antd";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import httpRequest from "../../Utils/httpsRequest";
import useLogout from "../../CustomHooks/useLogout";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber, formatStorageSize } from "../../Utils/helpers";
// import faitIcon from "../../Assets/images/FAITIcon.svg";
import { fetchPlans } from "../../store/slices/planSlice";
import TomiToken from "../../Assets/images/TomiToken.svg";
import { ProAccountIcon, ScaleIcon } from "../../Assets/Assets";
import AccountCard from "../../Common/Cards/AccountCard/AccountCard";
import { HTTP_METHOD, API_URL, HTTP_STATUS_CODE } from "../../constants";
function AccountTypeDashboard() {
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const [capValue, setCapValue] = useState(0);
  const { csrfToken } = useSelector((state) => state.auth);
  const [
    showTokenValue,
    // setShowTokenValue
  ] = useState(true);
  const { plans, loading } = useSelector((state) => state.plan);
  
  useEffect(() => {
    try {
      dispatch(fetchPlans({ csrfToken, handleLogOut }));
      (async () => {
        const response = await httpRequest(
          `${API_URL.COIN_MARKET_CAP}`,
          HTTP_METHOD.GET,
          null,
          null,
          null,
          handleLogOut,
        );

        if (response.code === HTTP_STATUS_CODE.OK) {
          console.log("response?.data", response.data?.price);
          setCapValue(Number(response.data?.price ?? 0));
        } else {
          toast.error("Something went wrong!");
        }
      })();
    } catch (error) {
      console.log("Error while fetching cap api : ", error);
    }
  }, []);

  const perMonth = " per month";

  const generateFeatures = (plan) => {
    
    return [
      `${formatStorageSize(plan?.storage)} Storage included`,
      `${formatStorageSize(plan?.meta_data?.bandwidth)} Bandwidth`,
      `${plan?.meta_data?.workspace} ${plan?.meta_data?.workspace == 1 ? "Workspace Member" : "Workspace Members"}`,
      `${plan?.meta_data?.gateway}  ${plan?.meta_data?.gateway===1 ?"Gateway":"Gateways" }`,
      `${formatNumber(plan?.meta_data?.requests)}  requests${perMonth}`,
      `Maximum number of files pinned can be ${formatNumber(plan?.meta_data?.pinned)}`,
      `${plan?.meta_data?.html_file === true ? "HTML Upload Supported" : "No HTML Upload Supported"}`,
    ];
  };

  const handleSubmit = (e) => {
    switch (e) {
      case "free":
        break;

      case "standard":
        break;

      default:
        break;
    }
  };

  // const onChange = (checked) => {
  //   setShowTokenValue(checked);
  // };

  // const fetchBalance = async () => {
  //   try {
  //     const erc20 = await ERC20Contract.init(walletProvider);
  //     const balance = await erc20.getBalance(walletAddress);
  //     const decimals = await erc20.getDecimals(walletAddress);

  //     if (balance > 0) {
  //       setUserBalance(Number(balance) / 10 ** (Number(decimals) ?? 18));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching balance:", error);
  //   }
  // };

  console.log("capValue : ", capValue);

  return (
    <div className="accountType">
      <h2>Choose Your Plan</h2>
      {/* <div className="accountType_switch">
        <div className="accountType_switch_head">
          <img src={faitIcon} alt="" />
          <h5>Pay with FIAT</h5>
        </div>
        <Switch defaultChecked onChange={onChange} />
        <div className="accountType_switch_head">
          <img src={TomiToken} alt="" />
          <h5>Pay with tomi token</h5>
        </div>
      </div> */}
      <div className="accountType_cards">
        {plans &&
          plans?.map((plan, index) => (
            <AccountCard
              key={index}
              title={plan?.name}
              price={`$${plan?.price ?? 0}`}
              // price={`$${Math.round((plan?.price ?? 0) * capValue * 100) / 100}`}
              duration="mo"
              TokenValue={showTokenValue ? showTokenValue : null}
              valueIconSrc={TomiToken}
              description={plan?.description || ""}
              features={generateFeatures(plan)}
              buttonText={
                plan?.name === "free"
                  ? "Activated"
                  : plan?.name === "pro"
                    ? "Start Pro Account"
                    : plan?.name === "scale"
                      ? "Start Scale Account"
                      : ""
              }
              onClick={handleSubmit}
              // value={plan?.price}
              value={`${Math.round(((plan?.price ?? 0) / capValue) * 100) / 100}`}
              icon={
                plan?.name == "pro" ? (
                  <ProAccountIcon />
                ) : plan?.name === "scale" ? (
                  <ScaleIcon />
                ) : null
              }
              // btnDisabled={plan?.name === "free" || index !== 0 }
              btnDisabled={index !== 0}
              btnActive={false}
              className="dashboard_account_card"
              loading={loading}
            />
          ))}
      </div>
    </div>
  );
}

export default AccountTypeDashboard;
