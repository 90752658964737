import {
  ArrowIcon,
  StoreIcon,
  // FolderIcon
} from "../../../Assets/Assets";
import {
  capitalizeFirstLetter,
  formatStorageSize,
} from "../../../Utils/helpers";
import React, { useEffect, useState } from "react";
import "./UpgradePlan.scss";
import { Progress } from "antd";
import { useSelector } from "react-redux";
import useLogout from "../../../CustomHooks/useLogout";
import httpRequest from "../../../Utils/httpsRequest";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { Path } from "../../../Routing/Constant/RoutePaths";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../../constants";
import { env } from "../../../constants/env";

function UpgradePlan() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogOut = useLogout();
  const [storageData, setStorageData] = useState(null);
  const { planType } = useSelector((state) => state.user);

  const upgardePath = location.pathname === Path.PLAN;

  try {
    useEffect(() => {
      const socketConnection = new WebSocket(
        `${env.websocketBackendUrl}${API_URL.WS_STORAGE_USAGE}`,
      );
      socketConnection.withCredentials = true;
      socketConnection.addEventListener("message", (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data?.storage_update) {
            setStorageData(data?.storage_update)
          } else if (data.success) {
            console.log("Success Message:", data);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      });

      socketConnection.addEventListener("open", () => {
        console.log("WebSocket connection established");
      });

      socketConnection.addEventListener("error", (error) => {
        console.error("WebSocket connection error:", error);
      });

      socketConnection.addEventListener("close", () => {
        console.log("WebSocket connection closed");
      });

      return () => {
        socketConnection.close();
      };
    }, []);
  } catch (error) {
    console.error("Error initializing WebSocket connection:", error);
  }

  useEffect(() => {
    (async () => {
      const response = await httpRequest(
        API_URL.GET_STORAGE,
        HTTP_METHOD.GET,
        null,
        null,
        null,
        handleLogOut,
        null,
      );
      if (response?.code === HTTP_STATUS_CODE.OK) {
        setStorageData(response?.data);
      } else {
        console.log(response?.response?.data?.message);
      }
    })();
  }, []);

  function changePath() {
    navigate(Path.PLAN);
  }

  return (
    <div className="upgradePlan">
      {storageData ? (
        <>
          <p className="upgradePlan_free">
            {planType ? capitalizeFirstLetter(planType) : "Free"} Trail Plan
          </p>
          <div className="upgradePlan_storage">
            <h4>
              <StoreIcon /> <span>Storage</span>
            </h4>
            <Progress
              size="small"
              percent={
                (Number(storageData?.storage) /
                  Number(storageData?.storage_limit)) *
                100
              }
              strokeColor="#FFF"
              showInfo={false}
            />
            {storageData ? (
              <p>
                {formatStorageSize(storageData?.storage ?? 0)} of{" "}
                {formatStorageSize(storageData?.storage_limit ?? 0)} used
              </p>
            ) : null}
          </div>
        </>
      ) : null}

      {/* <div className="upgradePlan_storage">
        <h4>
          <FolderIcon /> <span>Folders</span>
        </h4>
        <Progress
          size="small"
          percent={50}
          strokeColor="#FFF"
          showInfo={false}
        />
        <p>60.8 GB of 1 TB used</p>
      </div> */}

      {!upgardePath && (
        <ButtonCustom
          regularBtn
          label="Upgrade Plan"
          onClick={changePath}
          icon={<ArrowIcon />}
        />
      )}
    </div>
  );
}

export default UpgradePlan;
