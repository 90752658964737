import "./Login.scss";
import {
  REGEX,
  API_URL,
  ERR_MSG,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
} from "../../constants";
import {
  setUserTempData,
  setWltSignature,
} from "../../store/slices/uiSettingSlice";
import toast from "react-hot-toast";
import { BrowserProvider } from "ethers";
import { env } from "../../constants/env";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import httpRequest from "../../Utils/httpsRequest";
import useKeyPress from "../../Utils/validationUtils";
import { Turnstile } from "@marsidev/react-turnstile";
import { useDispatch, useSelector } from "react-redux";
import { Path } from "../../Routing/Constant/RoutePaths";
import { validateMail } from "../../Utils/validationUtils";
import { setUserData } from "../../store/slices/userSlice";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import LoginEmailSec from "../../Common/Components/LoginEmailSec/LoginEmailSec";

function PersonalAccount() {
  const ref = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nameErr, setNameErr] = useState("");
  const [mailErr, setMailErr] = useState("");
  const [isDisable, setDisable] = useState(true);
  const { walletProvider } = useWeb3ModalProvider();
  const [captchaToken, setCaptchaToken] = useState("");
  const { walletAddress } = useSelector((state) => state.auth);
  const [personalAccount, setPersonalAccount] = useState({
    name: "",
    email: "",
    useCase: "Large File Distribution",
  });
  const { userTempData, wltSignature } = useSelector(
    (state) => state.uiSettings,
  );

  useEffect(() => {
    if (userTempData?.email || userTempData?.name || userTempData?.useCase) {
      setPersonalAccount({
        useCase: userTempData?.useCase || "Large File Distribution",
        email: userTempData?.email || "",
        name: userTempData?.name || "",
      });
    }
  }, []);

  useEffect(() => {
    if (!walletAddress) {
      navigate(Path.WELCOME);
    }
  }, [walletAddress, navigate]);

  // console.log("captchaToken : ", captchaToken);

  useEffect(() => {
    if (
      nameErr ||
      mailErr ||
      !personalAccount?.email ||
      !personalAccount?.name ||
      !captchaToken
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    nameErr,
    mailErr,
    personalAccount?.email,
    personalAccount?.name,
    captchaToken,
  ]);

  /**
   * for managing the input state change
   * @param {*} e
   */
  const handleChange = (e) => {
    const name = e?.target?.name ?? "useCase";
    const value = e?.target?.value;
    let username;

    switch (name) {
      case "name":
        username = value.replace(/\s{2,}/g, " ");
        validateName(username);
        setPersonalAccount({
          ...personalAccount,
          name: username,
        });
        break;

      case "email":
        setPersonalAccount({
          ...personalAccount,
          email: value,
        });
        {
          const { isValidMail, error } = validateMail(value);
          if (!isValidMail) {
            setMailErr(error);
          } else {
            setMailErr("");
          }
        }
        break;

      case "useCase":
        setPersonalAccount({
          ...personalAccount,
          useCase: e,
        });
        break;

      default:
        break;
    }
  };

  /*
   * Resets the states
   */
  const resetState = () => {
    setPersonalAccount({
      name: "",
      email: "",
      useCase: "",
    });
  };

  /**
   * Submit user details to the server
   */
  const handleSubmit = async () => {
    try {
      if (walletAddress && !nameErr && !mailErr && !isDisable) {
        let data = {
          name: personalAccount.name,
          email: personalAccount.email,
          wallet_address: walletAddress,
          useCase: personalAccount.useCase,
          message: env.wltSignatureMsg,
          captcha_token: captchaToken ?? "",
        };
        if (wltSignature) {
          data.signature = wltSignature;
        } else if (walletProvider) {
          try {
            const provider = new BrowserProvider(walletProvider);
            const signer = await provider.getSigner();
            const signedMessage = await signer.signMessage(env.wltSignatureMsg);
            data.signature = signedMessage;
            dispatch(setWltSignature(signedMessage));
          } catch (error) {
            console.log("Error  while signing message : ", error);
            if (error instanceof Error) {
              const err = JSON.parse(JSON.stringify(error));
              if (err.code === "ACTION_REJECTED") {
                toast.error("User rejected the request");
              }
            } else {
              toast.error(error?.message ?? "Something went wrong");
            }
          }
        }
        setDisable(true);
        const response = await httpRequest(
          API_URL.REGISTER,
          HTTP_METHOD.POST,
          data,
          null,
          null,
          null,
        );

        dispatch(
          setUserTempData({
            email: personalAccount.email,
            name: personalAccount.name,
            useCase: personalAccount.useCase,
          }),
        );
        if (response?.code === HTTP_STATUS_CODE.OK) {
          dispatch(
            setUserData({
              email: personalAccount.email,
              name: personalAccount.name,
            }),
          );
          resetState();
          navigate(Path.VERIFY_OTP, {
            replace: true,
          });
          toast.success(response?.message);
        } else {
          console.log(response);
          toast.error(response?.error?.message || "Something went wrong");
        }
        ref.current?.reset();
      }
    } catch (error) {
      console.log("Error: ", error);
      toast.error("An error occurred during registration.");
    }
  };

  useKeyPress("Enter", handleSubmit, [handleSubmit]);

  /**
   * Validates the name
   * @returns boolean
   */

  const validateName = (value) => {
    const name = value.trim();

    if (name.length === 0) {
      setNameErr(ERR_MSG.REQUIRED_INPUT);
      return false;
    }

    if (name.length < 3) {
      setNameErr(ERR_MSG.SHORT_LENGTH);
      return false;
    }

    if (name.length >= 50) {
      setNameErr(ERR_MSG.LONG_LENGTH);
      return false;
    }
    if (!REGEX.STARTS_WITH_LETTER.test(name)) {
      setNameErr(ERR_MSG.STARTS_WITH_LETTER);
      return false;
    }

    if (/ {2,}/.test(name)) {
      setNameErr(ERR_MSG.CONSECUTIVE_SPACE);
      return false;
    }

    if (!REGEX.NAME.test(name)) {
      setNameErr(ERR_MSG.INVALID_NAME);
      return false;
    }

    setNameErr("");
    return true;
  };

  return (
    <>
      <div className="personalAccount">
        <InputCustom
          label={true}
          required
          minLength={3}
          maxLength={50}
          name="name"
          regularInput
          value={personalAccount.name}
          labletext="Full Name"
          placeholder="Enter Full Name"
          error={nameErr}
          onChange={handleChange}
        />
        <CustomSelect
          label={true}
          value={personalAccount.useCase}
          labelText="Use Case"
          required
          name="useCase"
          onChange={handleChange}
          defaultValue="Large File Distribution"
          options={[
            {
              value: "Large File Distribution",
              label: "Large File Distribution",
            },
            {
              value: "Medium File Distribution",
              label: "Medium File Distribution",
            },
            {
              value: "Small File Distribution",
              label: "Small File Distribution",
            },
          ]}
        />
        <Turnstile
          ref={ref}
          siteKey={env.captchaKey}
          onSuccess={(token) => {
            setCaptchaToken(token);
          }}
          onError={(error) => {
            console.log("Error in captcha : ", error);
            setCaptchaToken("");
          }}
        />
        <LoginEmailSec
          errorMsg={mailErr}
          disabled={isDisable}
          resetState={resetState}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          validateMail={validateMail}
          stateValues={personalAccount}
          setStateValue={setPersonalAccount}
          setMailErr={setMailErr}
          // setCaptchaToken={setCaptchaToken}
        />
      </div>
    </>
  );
}

export default PersonalAccount;
