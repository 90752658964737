/*eslint-disable*/
import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { ArrowIcon } from "../../Assets/Assets";
import DashCard from "../../Common/Components/DashCard/DashCard";
import DashboardGraph from "../../Common/Components/DashboardGraph/DashboardGraph";
// import Loader from "../../";

import UserLogCard from "../../Common/Components/UserLogCard/UserLogCard";
import img from "../../Assets/images/LoginBanner.png";
import { Path } from "../../Routing/Constant/RoutePaths";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../Common/Loader/Loader";

function Dashboard() {
  const navigate = useNavigate();
  const { planType } = useSelector((state) => state.user);
  const { isDashboardGuide } = useSelector((state) => state.auth);
  const isDashboardGuideStore = localStorage.getItem("setUserIntroGuide");
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (planType == null) return;
    if (!planType) {
      navigate(Path.ACCOUNT_TYPE);
    } else {
      setIsLoading(false);
    }
  }, [planType, navigate]);

  function changePath() {
    navigate(Path.PLAN);
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="dashboard_banner">
            <img src={img} alt="Dashboard Banner" />
            <div>
              <h3>Welcome to Storage</h3>
              <p>
                Begin by setting up folders to keep your files organized and
                easily accessible.
              </p>
            </div>
            <ButtonCustom
              borderBtn
              label="Upgrade Plan"
              onClick={changePath}
              icon={<ArrowIcon />}
            />
          </div>

          {!isDashboardGuide &&
            !isDashboardGuideStore &&
            pathname === "/dashboard" &&
            planType != null && <DashCard />}

          <DashboardGraph />

          <UserLogCard />
        </>
      )}
    </div>
  );
}

export default Dashboard;
