import React, { useState, useEffect } from "react";
import "./Settings.scss";
import {
  MessageIcon,
  PasswordKeyIcon,
  UserIcon2,
  ProAccountIcon,
  ScaleIcon,
} from "../../Assets/Assets";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { Col, Row, Switch, Skeleton, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import CommonModal from "../../Common/CommonModal/CommonModal";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import httpRequest from "../../Utils/httpsRequest";
import useKeyPress from "../../Utils/validationUtils";
import {
  API_URL,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
  ERR_MSG,
  REGEX,
} from "../../constants";
import { getName, setName } from "../../store/slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import useLogout from "../../CustomHooks/useLogout";
import toast from "react-hot-toast";
function Settings() {
  const handleLogOut = useLogout();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nameErr, setNameErr] = useState("");
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const dispatch = useDispatch();

  const { email, planType } = useSelector((state) => state.user);

  const [username, setUsername] = useState(useSelector(getName));

  const [inputName, setInputName] = useState(useSelector(getName));

  const showModal = () => {
    setIsModalVisible(true);
    setInputName(username);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNameErr("");
  };

  const handleEditName = (e) => {
    setInputName(e.target.value);
    validateName(e.target.value);
  };

  const handleClearError = () => {
    setInputName("");
  };

  useEffect(() => {
    if (nameErr) {
      setIsEditDisabled(true);
    } else if (inputName != username) {
      setIsEditDisabled(false);
    } else if (inputName == username) {
      setIsEditDisabled(true);
    }
  }, [nameErr, username, inputName]);

  /**
   * Validates the name
   * @returns boolean
   */

  const validateName = (value) => {
    const name = value.trim();

    if (name.length === 0) {
      setNameErr(ERR_MSG.REQUIRED_INPUT);
      return false;
    }

    if (name.length <= 2) {
      setNameErr(ERR_MSG.SHORT_LENGTH);
      return false;
    }

    if (name.length > 50) {
      setNameErr(ERR_MSG.LONG_LENGTH);
      return false;
    }
    if (!REGEX.STARTS_WITH_LETTER.test(name)) {
      setNameErr(ERR_MSG.STARTS_WITH_LETTER);
      return false;
    }

    if (/ {2,}/.test(name)) {
      setNameErr(ERR_MSG.CONSECUTIVE_SPACE);
      return false;
    }

    if (!REGEX.NAME.test(name)) {
      setNameErr(ERR_MSG.INVALID_NAME);
      return false;
    }

    setNameErr("");
    return true;
  };

  const clickHandler = async () => {
    try {
      if (!isEditDisabled && inputName?.length) {
        setIsEditDisabled(true);
        let name = inputName;

        const response = await httpRequest(
          API_URL.EDIT_USER,
          HTTP_METHOD.PUT,
          { name: inputName },
          null,
          null,
          handleLogOut,
        );
        if (response?.code === HTTP_STATUS_CODE.OK) {
          setUsername(name);
          dispatch(setName(name));
          toast.success(response?.message);
          handleOk();
        } else {
          toast.error(response?.error?.message || "Something went wrong");
          handleOk();
        }

        handleClearError();
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
      handleClearError();
    }
  };

  useKeyPress("Enter", clickHandler, [clickHandler]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const accessKeys = [
    {
      usericon: <UserIcon2 />,
      emailicon: <MessageIcon />,
      name: <Tooltip title={username}>{username}</Tooltip>,
      email: <Tooltip title={email}>{email}</Tooltip>,
      accessName: "Profile",
      createdDate: "July 03, 2024 | 07:24 PM",
      CardBtn: true,
      btnLabel: "Edit",
      onClickBtn: showModal,
    },
    {
      userIcon:
        planType === "free" ? (
          <UserIcon2 />
        ) : planType === "pro" ? (
          <ProAccountIcon />
        ) : (
          <ScaleIcon />
        ),
      name: <span className="ellipsis  upperCase">{planType}</span>,
      accessName: "Current Plan",
      CardBtn: true,
      btnLabel: "View Plan",
      onClickBtn: () => {
        navigate("/upgrade-plan");
      },
    },
  ];

  return (
    <div>
      <Row gutter={[20, 20]} className="setting">
        {loading ? (
          // Skeleton loader for loading state
          <>
            {[1, 2, 3, 4].map((_, index) => (
              <Col lg={6} className="setting_card commonCardBg" key={index}>
                <Skeleton active paragraph={{ rows: 2 }} />
                <Skeleton.Button active />
              </Col>
            ))}
          </>
        ) : (
          accessKeys.map((key, index) => (
            <Col lg={6} className="setting_card commonCardBg" key={index}>
              <div>
                <div className="setting_card_top">
                  <h4>{key.accessName}</h4>
                  <div>{/* <MenuDots /> */}</div>
                </div>
                <div className="setting_card_bottom">
                  <div className="setting_card_bottom_list">
                    {key.usericon} <h3 className="ellipsis">{key.name}</h3>
                  </div>
                  <div className="setting_card_bottom_list">
                    {key.emailicon} <h3 className="ellipsis">{key.email}</h3>
                  </div>

                  {key.Switch && (
                    <div className="setting_card_bottom_switch">
                      <p>Enable</p>
                      <Switch />
                      <p>Disable</p>
                    </div>
                  )}
                </div>
              </div>
              {key.CardBtn &&
                (key.to ? (
                  <Link to={key.to}>
                    <ButtonCustom
                      onClick={key.onClickBtn}
                      regularBtn
                      label={key.btnLabel}
                    />
                  </Link>
                ) : (
                  <ButtonCustom
                    onClick={key.onClickBtn}
                    regularBtn
                    label={key.btnLabel}
                  />
                ))}
            </Col>
          ))
        )}
      </Row>
      <CommonModal
        visible={isModalVisible}
        title="Edit Name"
        className=" editNameModal"
        BtnText="Save"
        paragraph="Update your name below."
        icon={<PasswordKeyIcon />}
        customBtutton
        btnDisabled={isEditDisabled}
        onClick={clickHandler}
        content={
          <InputCustom
            regularInput
            placeholder="Name"
            label
            minLength={3}
            maxLength={50}
            value={inputName}
            error={nameErr}
            onChange={handleEditName}
            labletext="Enter Full Name"
            required
          />
        }
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}

export default Settings;
