import React, { useState } from "react";
import "./BillingInformation.scss";
import { PasswordKeyIcon } from "../../Assets/Assets";

import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import BillingTable from "./BillingTable";
import CommonModal from "../../Common/CommonModal/CommonModal";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";

function BillingInformation() {
  const [visibleModal, setVisibleModal] = useState(null);

  // const showModal = (modalType) => {
  //   setVisibleModal(modalType);
  // };

  const handleOk = () => {
    setVisibleModal(null);
  };

  const handleCancel = () => {
    setVisibleModal(null);
  };

  // const onChange = (checked) => {
  //   console.log(`switch to ${checked}`);
  // };

  // const accessKeys = [
  //   {
  //     icon: <LocationIcon />,
  //     name: "4454 Saints Alley Plant...",
  //     accessName: "Billing Address",
  //     createdDate: "July 03, 2024 | 07:24 PM",
  //     CardBtn: true,
  //     btnLabel: "Edit",
  //     onClick: () => showModal("billingAddress"),
  //   },
  //   {
  //     icon: <BillList />,
  //     name: "Tax Information",
  //     accessName: "No Tax Information Added",
  //     createdDate: "July 03, 2024 | 07:24 PM",
  //     CardBtn: true,
  //     btnLabel: "Add",
  //     menuDot: true,
  //     onClick: () => showModal("taxInformation"),
  //   },
  // ];

  return (
    <div className="billing ">
      {/* <div className="setting">
        {accessKeys.map((key, index) => (
          <div className="setting_card commonCardBg" key={index}>
            <div className="setting_card_top">
              <div>
                <div>
                  {key.icon} <h4>{key.name}</h4>
                </div>
                {key.menuDot && <MenuDots />}
              </div>
              <p>{key.accessName}</p>
            </div>
            <div className="setting_card_bottom">
              {key.CardBtn && (
                <ButtonCustom
                  onClick={key.onClick}
                  regularBtn
                  label={key.btnLabel}
                />
              )}
              {key.Switch && (
                <div className="setting_card_bottom_switch">
                  <p>Enable</p>
                  <Switch onChange={onChange} /> <p>Disable</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div> */}

      <div className="billing_bottom">
        <h3>Billing History</h3>
        {/* <InputCustom
          customClass="commonSearchInput"
          label
          searchInputs
          placeholder="Search Container"
        /> */}
      </div>
      <div>
        <BillingTable />
      </div>

      <CommonModal
        visible={visibleModal === "billingAddress"}
        title="Update Billing Address"
        BtnText="Save"
        customBtutton
        paragraph="Lorem ipsum dolor sit amet consectetur. Mollis fringilla viverra sagittis sed aliquet malesuada faucibus felis cum. At aliquet condimentum et accumsan mauris porttitor egestas."
        icon={<PasswordKeyIcon />}
        content={
          <>
            <InputCustom
              regularInput
              placeholder="Name"
              value="Jaqueline C. Banks"
              label
              labletext="Full Name"
              required
            />
            <CustomSelect
              label
              labelText="Country or Region"
              defaultValue="United State"
              options={[
                { value: "United State", label: "United State" },
                { value: "India", label: "India" },
                { value: "Canada", label: "Canada" },
              ]}
            />
            <InputCustom
              regularInput
              placeholder="Address"
              value="4454 Saints Alley Plant City, FL 33564"
              label
              labletext="Address"
              required
            />
          </>
        }
        onOk={handleOk}
        onCancel={handleCancel}
      />

      <CommonModal
        visible={visibleModal === "taxInformation"}
        title="Add Tax ID"
        BtnText="Save"
        customBtutton
        paragraph="Lorem ipsum dolor sit amet consectetur. Mollis fringilla viverra sagittis sed aliquet malesuada faucibus felis cum. At aliquet condimentum et accumsan mauris porttitor egestas."
        icon={<PasswordKeyIcon />}
        content={
          <>
            <CustomSelect
              label
              labelText="Country or Region"
              defaultValue="United State"
              options={[
                { value: "United State", label: "United State" },
                { value: "India", label: "India" },
                { value: "Canada", label: "Canada" },
              ]}
            />
            <CustomSelect
              label
              labelText="Tax ID Type"
              defaultValue="Select Tax ID"
              options={[
                { value: "Select Tax ID", label: "Select Tax ID" },
                { value: "India", label: "India" },
                { value: "Canada", label: "Canada" },
              ]}
            />
            <InputCustom
              regularInput
              placeholder="Tax ID"
              label
              labletext="Enter Here"
              required
            />
          </>
        }
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}

export default BillingInformation;
