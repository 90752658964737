import "./Loader.scss";
import React from "react";
import loadingGif from "../../Assets/images/Tomi Loader.gif";

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={loadingGif} alt="" className="loader-image" />
    </div>
  );
};

export default Loader;
