import React from "react";
import "./ComingSoon.scss";
import PropTypes from "prop-types";

function ComingSoon({ className = "", customStyle = {} }) {
  const letters = "Coming Soon...".split("");

  return (
    <div className={`planComingSoon ${className}`} style={customStyle}>
      <div className="waviy">
        {letters.map((letter, index) => (
          <span key={index} style={{ "--i": index + 1 }}>
            {letter === " " ? <>&nbsp;</> : letter}
          </span>
        ))}
      </div>
    </div>
  );
}

ComingSoon.propTypes = {
  className: PropTypes.string,
  customStyle: PropTypes.object,
};

export default ComingSoon;
