import { Skeleton } from "antd";
import React from "react";
import "./DashCard.scss";

function DashCardSekelton() {
  return (
    <div className="dashCardSekelton commonCardBg">
      <Skeleton active />
      <Skeleton.Button active />
    </div>
  );
}

export default DashCardSekelton;
