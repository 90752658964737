import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGridView: true, // Default view is grid
};

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    toggleView(state) {
      state.isGridView = !state.isGridView;
    },
  },
});

export const { toggleView } = viewSlice.actions;
export default viewSlice.reducer;
