import React from "react";
import DashboardLayout from "../../Common/Layouts/DashboardLayout/DashboardLayout.jsx";
import ScrollToTop from "../../Common/ScrollToTop/ScrollToTop";

const Private = () => {
  return (
    <ScrollToTop>
      <DashboardLayout />
    </ScrollToTop>
  );
};

export default Private;
