import React from "react";
import "./CustomTooltip.scss";
import PropTypes from "prop-types";
import moment from "moment/moment";
export const CustomTooltip = ({
  active,
  isGraph,
  payload,
  period,
  yAxisUnit,
}) => {
  if (active && payload && payload.length) {
    return isGraph ? (
      <div className="tooltip">
        {period != "year" && (
          <p
            dangerouslySetInnerHTML={{
              __html: `Date: ${moment(payload[0]?.payload?.date)
                .format("Do MMM")
                .replace(/(\d+)(st|nd|rd|th)/, "$1<sup>$2</sup>")}`,
            }}
          />
        )}
        {period == "day" && (
          <p>Time : {moment(payload[0]?.payload?.date).format("LT")}</p>
        )}
        {payload.map((entry, index) => (
          <p
            key={`tooltip-item-${index}`}
          >{`${entry.name == "upload_size" ? "Upload Size" : "Download Size"}: ${entry.value} ${yAxisUnit}`}</p>
        ))}
      </div>
    ) : (
      <div className="tooltip">{payload?.[0].value}%</div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  period: PropTypes.string,
  yAxisUnit: PropTypes.string,
  label: PropTypes.string,
  isGraph: PropTypes.bool,
};
