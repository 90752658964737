import React from "react";
import "./NoLonger.scss";
// import HeaderCommon from "../../Common/Components/HeaderCommon/HeaderCommon";
import ErrorImage from "../../Assets/images/Error404.png";

function NoLonger() {
  return (
    <div className="noLonger">
      {/* <HeaderCommon /> */}
      <div className="shortContainer">
        <div className="noLonger_inner shareCard">
          <img src={ErrorImage} alt="" />
          <h4>&#34;The file you are looking for, no longer exists&#34;</h4>
        </div>
      </div>
    </div>
  );
}

export default NoLonger;
