import { BrowserProvider, ethers } from "ethers";
import { env } from "../constants/env";
import ERC20abi from "../constants/abi/erc20.json";

class ERC20Contract {
  static instance = null;
  contract = null;

  constructor() {
    if (ERC20Contract.instance) {
      return ERC20Contract.instance;
    }
    ERC20Contract.instance = this;
  }

  static async init(walletProvider) {
    if (!ERC20Contract.instance) {
      const instance = new ERC20Contract();
      await instance._initialize(walletProvider);
    }
    return ERC20Contract.instance;
  }

  async _initialize(walletProvider) {
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      this.contract = new ethers.Contract(
        env.tokenContractAddress,
        ERC20abi,
        signer,
      );
    } catch (error) {
      console.error("Failed to initialize contract:", error);
      throw error;
    }
  }

  async getBalance(address) {
    try {
      console.log("this.contract : ", this.contract);

      if (!this.contract)
        throw new Error("Contract instance is not initialized.");
      return await this.contract.balanceOf(address);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
      throw error;
    }
  }

  async getDecimals() {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");
      return await this.contract.decimals();
    } catch (error) {
      console.error("Failed to fetch decimals:", error);
      throw error;
    }
  }
}

export default ERC20Contract;
