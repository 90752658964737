import React from "react";
import ScrollToTop from "../../Common/ScrollToTop/ScrollToTop";
import OnboardAuthLayout from "../../Common/Layouts/OnboardLayout/OnboardAuthLayout";

const Public = () => {
  return (
    <ScrollToTop>
      <OnboardAuthLayout />
    </ScrollToTop>
  );
};

export default Public;
